/* .container {
	padding: 4.5rem 0 6.4rem;
	background-color: var(--color6);
	flex: 1;
} */

.title {
	/* margin: 0 0 1.875rem; */
	font-size: 2rem;
	font-weight: 500;
	line-height: normal;
	text-transform: uppercase;
}

.categoryList {
	margin: 6.4rem 0 0;
	padding: 0 2.8rem;
}

.categoryContainer {
	display: flex;
	position: relative;
	z-index: 1;
}
.bookmark {
	position: absolute;
	bottom: -4.8rem;
	left: 50%;
	transform: translate(-50%, 0);
	width: 18rem;
	height: 11rem;
	background-color: transparent;
	border-radius: 2.8rem 2.8rem 0 0;
	z-index: -1;
}

.category {
	min-width: 12.5rem;
}

.category > div:nth-of-type(1) {
	width: 8.4rem;
	height: 8.4rem;
}

.category > div > svg {
	width: 4.2rem;
	height: 4.2rem;
}
.category > div > svg > path {
	fill: #fff;
}

.content {
	margin: 2.8rem auto 0;
	padding: 6.4rem;
	max-width: 1440px;
	background-color: #fff;
	border-radius: 2.8rem;
	box-sizing: border-box;
}

/* Laptop */
@media (max-width: 1550px) {
	.content {
		max-width: 1000px;
	}
}

@media (max-width: 1024px) {
	.content {
		max-width: 850px;
	}
}

/* Tablet, mobile */
@media (max-width: 900px) {
	.content {
		max-width: 850px;
	}
}
