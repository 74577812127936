.list {
	margin: 0;
	padding: 0;
	display: flex;
	flex-direction: column;
	gap: 2.4rem;
	list-style-type: none;
}

.method {
	display: flex;
	align-items: center;
	gap: 1.8rem;
	max-width: 33.5rem;
	font-size: 1.8rem;
}

.active {
	border-radius: 1rem;
	box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.35);
}

.method > svg {
	width: 5rem;
	height: 5rem;
}

@media (max-width: 900px) {
	.method {
		font-size: 16px;
	}
	.method > svg {
		width: 35px;
		height: 35px;
	}
}
