.menu,
.menu::after,
.menu::before {
	position: relative;
	margin: 0 1rem;
	content: '';
	width: 0.8rem;
	height: 0.8rem;
	top: 0px;
	right: 0px;
	border-radius: 50%;
	background-color: var(--color3);
}
.menu::after {
	position: absolute;
	margin: 0;
	top: 0px;
	right: -1rem;
}
.menu::before {
	position: absolute;
	margin: 0;
	top: 0px;
	right: 1rem;
}

.menuList {
	position: absolute;
	padding: 1rem;
	top: 1rem;
	right: 2rem;
	list-style-type: none;
	background-color: #fff;
	border-radius: 1rem;
	box-shadow: 1.4px 1.4px 33.42px 0px rgba(0, 0, 0, 0.35);
}
.menuList > li {
	padding: 0.5rem;
	width: max-content;
	border-radius: 1rem;
}
.menuList > li:hover {
	cursor: pointer;
	background-color: var(--color7);
}
