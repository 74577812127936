.container {
	display: grid;
	grid-template-columns: 1fr 10fr;
	align-items: center;
}

.container > div {
	display: flex;
	align-items: center;
	gap: 1.2rem;
}

.container > div > svg {
	width: 6.2rem;
	height: 6.2rem;
}
.container > div > svg > g > path {
	fill: url('#Gradient');
}

.serviceNameWrp {
	display: flex;
	flex-direction: column;
	gap: 0.4rem;
	font-size: 2rem;
	font-weight: 500;
}

.serviceNameWrp > h5 {
	font-size: 1.6rem;
	color: var(--color3);
}

@media (max-width: 900px) {
	.container {
		padding: 10px 16px;
		display: flex;
		gap: 10px;
		background-color: #fff;
		border-radius: 20px;
	}
	.container > div > svg {
		width: 50px;
		height: 50px;
	}
	.container > svg {
		margin: 0 0 0 auto;
		width: 35px;
		height: 35px;
	}
	.serviceNameWrp {
		gap: 0.25em;
		font-size: 18px;
	}
	.serviceNameWrp > h5 {
		font-size: 16px;
	}
}

@media (max-width: 550px) {
	.serviceNameWrp {
		font-size: 14px;
	}
	.serviceNameWrp > h5 {
		font-size: 12px;
	}
}
