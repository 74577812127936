.container {
	padding: 0.625em 1em;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	/* font-size: 1em; */
	background-color: #fff;
	border-radius: 2em;
	box-sizing: border-box;
}
.container > div {
	display: grid;
	grid-template-columns: max-content 1fr;
	align-items: center;
	gap: 2rem;
}
.container > div > div {
	width: 6em;
	height: 6em;
}
.container > div > div::after {
	display: none;
}
.container > div > div > svg {
	width: 2.6em;
	height: 2.6em;
}
.container > div > div > svg > path {
	fill: #fff;
}
