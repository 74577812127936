.wrp {
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	gap: 1.6rem;
	width: min-content;
	line-height: normal;
	transform: translateY(0);
	transition: all 500ms ease;
}
.wrp:hover {
	cursor: pointer;
	transform: translateY(-2rem);
}

.iconBlock > svg {
	width: 6rem;
	height: 6rem;
}

.iconBlock > svg > g > path[fill] {
	fill: #fff;
}
.iconBlock > svg > g > path[stroke] {
	stroke: #fff;
}

.textContainer {
	display: flex;
	flex-grow: 1;
	justify-content: center;
	flex-direction: column;
}
