.container {
	position: relative;
}

.userBlock {
	padding: 0.4rem 3.2rem 0.4rem 0;
	display: flex;
	align-items: center;
	gap: 1.1rem;
	font-family: 'Inter';
	font-weight: 500;
	border-radius: 0.8rem;
	position: relative;
}
.userBlock:hover {
	cursor: pointer;
	background-color: #f8f8f8;
}
.userBlock:active {
	background-color: inherit;
}

.icon {
	width: 3.2rem;
	height: 3.2rem;
	fill: var(--color3);
}

.userBlock span::before,
.userBlock span::after {
	position: absolute;
	content: '';
	width: 1.2rem;
	height: 1px;
	top: 2rem;
	right: 1.1rem;
	background-color: #555555;
	border-radius: 2px;
	transform: rotate(45deg);
}
.userBlock span::after {
	right: 0.2rem;
	transform: rotate(-45deg);
}

.userBlock span.active::before {
	transform: rotate(-45deg);
}
.userBlock span.active::after {
	transform: rotate(45deg);
}
