.container {
	display: flex;
	flex-direction: column;
	gap: 3.2rem;
}
.headRow {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.headRow > div {
	display: flex;
	align-items: center;
	gap: 2rem;
}
.headRow > div:last-of-type {
	color: var(--color3);
	font-size: 1.4rem;
}

.title {
	font-size: 2rem;
	font-weight: 500;
	line-height: normal;
	text-transform: uppercase;
}

.search {
	padding: 0.6rem 1.6rem;
	display: flex;
	align-items: center;
	border-radius: 2.6rem;
	background-color: #f6f6f6;
	box-sizing: border-box;
}
.search > input {
	border: none;
}
.search > input::placeholder {
	color: var(--color5);
}
.search > svg {
	width: 2rem;
	height: 2rem;
}
.search > svg > path {
	fill: var(--color5);
}

.table {
	display: flex;
	flex-direction: column;
}

.headRow {
	font-size: 18px;
}

.row {
	padding: 10px 0;
	display: grid;
	grid-template-columns: 3fr 1fr 2fr 2fr 3fr 2fr 1fr;
	justify-items: flex-start;
	align-items: center;
	border-bottom: 1px solid var(--color5);
	box-sizing: border-box;
}
.row > td:last-of-type,
.row > th:last-of-type {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
}
/* .row > td:last-of-type > div:hover {
	cursor: pointer;
} */
