.list {
	position: absolute;
	margin: 0;
	padding: 0;
	top: 13rem;
	width: 100%;
	max-height: 30vh;
	overflow-y: auto;
	list-style-type: none;
	background-color: #fff;
	border-radius: 0 0 2.6rem 2.6rem;
	border: 1px solid var(--color5);
	box-sizing: border-box;
	/* box-shadow: 2px 2px 14px rgba(0, 0, 0, 0.25); */
	/* z-index: 10; */
}

.list::-webkit-scrollbar {
	width: 2px;
	background-color: #e7e7e7;
	border-radius: 2px;
}
.list::-webkit-scrollbar-thumb {
	width: 2px;
	background-color: var(--main-color);
	border-radius: 2px;
}

.item {
	/* margin: 0 0 5px; */
	padding: 10px 15px;
	/* font-size: 0.7em; */
	/* font-size: 13px; */
	line-height: 1.5rem;
}
.item:hover {
	cursor: pointer;
	background-color: rgba(0, 0, 255, 0.151);
}
