.title {
	font-size: 2rem;
	font-weight: 500;
	line-height: 3rem;
	text-transform: uppercase;
}

@media (max-width: 900px) {
	.title {
		font-size: 1.25em;
	}
}
