.container {
	padding: 30px 0 0;
	display: flex;
	flex-direction: column;
	gap: 1.6rem;
	box-sizing: border-box;
}
/* .container > p {
	align-self: flex-end;
}
.container > p:hover {
	cursor: pointer;
} */

.container > nav {
	margin: 0;
}

.title {
	margin: 0;
}

.section {
	margin: 0 0 20px;
	display: grid;
	grid-template-columns: 6fr 4fr;
}
