.historyBlockHeadRow {
	margin: 0 0 1rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.historyBlockHeadRow > h6 {
	font-size: 1.2rem;
	font-weight: 700;
}

.historyRow {
	padding: 0.6rem 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: 1rem;
	border-bottom: 1px solid #d3d3da;
	box-sizing: border-box;
}
