.form {
	max-width: 1000px;
	width: 100vw;
	align-items: normal;
}

.wrp {
	display: grid;
	grid-template-columns: 60% 1fr;
	align-items: flex-start;
	gap: 2rem 3.2rem;
}
.wrp > div:nth-of-type(1) {
	display: flex;
	flex-direction: column;
	gap: 2rem;
}

.block {
	padding: 2rem;
	display: flex;
	flex-direction: column;
	gap: 1.6rem;
	background-color: var(--color6);
	border-radius: 1.6rem;
	box-sizing: border-box;
}
.block > h4 {
	font-size: 2rem;
}

.input {
	padding: 1rem 2rem;
	background-color: #fff;
	font-size: 1.4rem;
	border-radius: 1.6rem;
}

.btns {
	display: flex;
	gap: 2rem;
	justify-content: center;
}
.btns > button {
	padding: 0.8rem 3.2rem;
	width: max-content;
}
.btns > button:nth-of-type(2) {
	background: var(--gradient1);
	color: #fff;
}
.btns > button:nth-of-type(2):hover {
	background: #fff;
	color: var(--color3);
}
.btns > button:nth-of-type(2):active {
	background: var(--gradient1);
	color: #fff;
}
