.container {
	padding: 2rem 0 0;
	margin: 0 auto;
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	gap: 0 4rem;
	max-width: 1440px;
	box-sizing: border-box;
	/* transition: all 1.5; */
}

.item {
	padding: 1.6rem 1rem;
	display: flex;
	align-items: center;
	gap: 1rem;
	border-top: 1px solid #d3d3da;
	position: relative;
	transform: translateY(0);
	transition: all 500ms;
}
.item::after,
.item::before {
	position: absolute;
	content: '';
	top: 3rem;
	right: 1rem;
	width: 0.9rem;
	height: 1px;
	background-color: var(--color2);
	border-radius: 1px;
	transform: rotate(45deg);
}
.item::before {
	top: 3.5rem;
	transform: rotate(-45deg);
}

.item svg {
	width: 3rem;
	height: 3rem;
}
.item svg > g > path {
	fill: url(#Gradient);
}

.item:hover {
	cursor: pointer;
	border-top: none;
	border-radius: 1.6rem;
	box-shadow: 0px 0px 1rem 0px rgba(0, 0, 0, 0.17);
	transform: translateY(-1rem);
}
.item:hover svg {
	/* fill: #fff; */
}
.item:hover::before,
.item:hover::after {
	/* background-color: #fff; */
}

@media (max-width: 1550px) {
	.container {
		max-width: 1000px;
	}
}

@media (max-width: 1024px) {
	.container {
		max-width: 850px;
	}
}

/* Tablet */
@media (max-width: 900px) {
	.container {
		max-width: 665px;
	}
}
