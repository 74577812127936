.stickyBlock {
	position: sticky;
	top: 20px;
	max-height: 95vh;
	display: grid;
	grid-template-rows: max-content max-content 1fr;
}

.flexBlock {
	margin: 0 0 20px;
	display: flex;
	justify-content: space-between;
}

.btn {
	width: max-content;
	gap: 10px;
	color: #fff;
	background: var(--color3);
}
.btn:hover {
	color: var(--color3);
	background: #fff;
}

.btn > svg > path {
	fill: #fff;
}
.btn:hover > svg > path {
	fill: var(--color3);
}

.checkboxContainer {
	margin: 0 0 30px;
	display: flex;
}
.checkboxContainer > label {
	margin: 0 30px 0 0;
	display: flex;
	align-items: center;
}
.checkboxContainer > label:hover {
	cursor: pointer;
}

.checkboxContainer > label > span {
	white-space: nowrap;
}

.checkboxContainer > label > .checkbox {
	margin: 0 10px 0 0;
}

.modalWindow {
	min-width: 500px;
}
