.flag {
	/* position: absolute; */
	/* left: 0; */
	width: 100%;
	box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.25);
}

.line {
	width: 100%;
	height: 4px;
	background-color: #fff;
}

.blue {
	background-color: #3c6bc7;
}

.red {
	background-color: #d52b1e;
}
