.container {
	/* display: flex;
	justify-content: space-between;
	align-items: flex-start; */
}

.payData {
	display: flex;
	flex-direction: column;
	gap: 3rem;
}

.headRow {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.edit {
	display: flex;
	align-items: center;
	gap: 1rem;
	color: var(--color3);
}
.edit > svg {
	width: 3rem;
	height: 3rem;
}
.edit > svg > g > path {
	fill: var(--color3);
}

@media (max-width: 900px) {
	.edit > p {
		display: none;
	}
	.edit > svg {
		width: 2em;
		height: 2em;
	}
}
