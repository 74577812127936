/* Card */
.card {
	position: relative;
	/* width: 28.125em; */
	width: 330px;
	box-sizing: border-box;
	/* z-index: 2; */
}
.card:hover {
	cursor: pointer;
}

.img {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 14em;
	background-repeat: no-repeat;
	background-size: cover;
	border-radius: 2em 2em 0 0;
	position: relative;
}
/* .card:hover .img::after {
	position: absolute;
	content: '';
	top: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(255, 255, 255, 0.7);
	backdrop-filter: blur(2px);
	border-radius: 2em 2em 0 0;
	box-sizing: border-box;
} */

/* .img > button {
	display: none;
	width: max-content;
	color: var(--color1);
	border: none;
	z-index: 1;
}
.card:hover .img > button {
	display: block;
} */

.info {
	margin: -2.2em 0 0;
	padding: 1.25em 1.25em 0.75em 1.25em;
	display: flex;
	flex-direction: column;
	color: var(--color2);
	line-height: normal;
	background: linear-gradient(180deg, #fdfdfc 0%, #f1eeed 54.24%, #efeded 100%);
	backdrop-filter: blur(1em);
	border-radius: 2em;
	position: relative;
}

.titleCardContainer {
	margin: 0 0 0.6em;
	display: flex;
	align-items: center;
	gap: 0.6em;
}
.titleCardContainer > svg {
	width: 2.8em;
	height: 2.8em;
}
.titleCardContainer > svg > g > path {
	fill: url(#Gradient);
}

.category {
	color: var(--color3);
	font-size: 0.9em;
}

.service {
	color: var(--color1);
	font-size: 1em;
}

.description {
	font-size: 0.8em;
	line-height: 1.25em;
}

.price {
	align-self: flex-end;
	font-size: 1.25em;
	font-weight: 500;
	color: var(--color3);
}

.card > svg {
	position: absolute;
	content: '';
	width: 1.625em;
	height: 1.625em;
	top: -0.25em;
	right: 1.9em;
	fill: #fff;
	filter: drop-shadow(1px 1px 0.25em rgba(0, 0, 0, 0.25));
}
.card > svg:hover {
	cursor: pointer;
}
.card > svg.active {
	fill: var(--color4);
}
