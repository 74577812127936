.container {
	margin: 0 10px 10px 0;
	display: flex;
	justify-content: space-between;
}
.fieldName {
	margin: 0 10px 0 0;
	padding: 10px 15px;
	width: 100%;
	font-size: 1.4rem;
	/* font-size: 13px; */
	line-height: 16px;
	border: 1px solid var(--main-color);
	border-radius: 8px;
	box-sizing: border-box;
}

.btnContainer {
	display: flex;
	align-items: center;
}
