/* Inter */
@font-face {
	font-family: 'Inter';
	src: url(./fonts/Inter/Inter-Regular.ttf),
		url(./fonts/Inter/Inter-Regular.eot), url(./fonts/Inter/Inter-Regular.woff2),
		url(./fonts/Inter/Inter-Regular.woff);
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Inter';
	src: url(./fonts/Inter/Inter-Medium.ttf), url(./fonts/Inter/Inter-Medium.eot),
		url(./fonts/Inter/Inter-Medium.woff2), url(./fonts/Inter/Inter-Medium.woff);
	font-weight: 500;
	font-style: normal;
}

/* Snell-Roundhand */
@font-face {
	font-family: 'Snell-Roundhand';
	src: url(./fonts/SnellRoundhand/Snell-Roundhand-Bold.ttf),
		url(./fonts/SnellRoundhand/Snell-Roundhand-Bold.otf),
		url(./fonts/SnellRoundhand/Snell-Roundhand-Bold.woff);
	font-weight: 700;
	font-style: normal;
}

:root {
	--color1: #444;
	--color2: #555;
	--color3: #0a42d3;
	--color4: #c91212;
	--color5: #bcbcbc;
	--color6: #f6f9fb;
	--color7: #f6f6f6;
	--color8: #c0c0c0;
	--gradient1: linear-gradient(180deg, #0a42d3 0%, #1f7fd8 100%);
	--gradient2: linear-gradient(180deg, #c91212 0%, #f04a15 100%);
}

/* Draft.css:11 */
/* .public-DraftStyleDefault-ltr {
	text-align: inherit !important; bug: when moving to the second line, the alignment does not work
} */
/**/

html {
	font-size: 62.5%;
}
body {
	margin: 0;
	font-family: 'Inter';
	font-size: 1.6rem;
	/* font-size: 1em; */
	line-height: normal;
	color: var(--color1);
}
button,
input,
textarea {
	font-size: 1.6rem;
	font-family: 'Inter';
}

h3,
h4,
h5,
h6 {
	margin: 0;
	font-weight: 500;
}
button {
	/* font-size: 16px; */
	/* line-height: 2.4rem; */
	padding: 0;
	color: var(--color1);
	background-color: inherit;
	border-style: none;
}
a {
	color: var(--color1);
	text-decoration: none;
	/* font-size: 1.2rem; */
	/* line-height: 1.6rem; */
}
p {
	margin: 0;
}
input:focus-visible,
textarea:focus-visible {
	outline: none;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
ul {
	margin: 0;
	padding: 0;
}

.title {
	/* margin: 0 0 5rem; */
	font-size: 5.7rem;
	font-weight: 500;
	line-height: normal;
	text-transform: uppercase;
}

.text {
	margin: 0;
	font-size: 1.6rem;
	line-height: 20px;
}

/*  /////////////////////////////////////////  */
div .DraftEditor-editorContainer {
	z-index: 0;
}

div .public-DraftStyleDefault-block {
	margin: 0;
}
.RichEditor-root {
	background: #fff;
	border: 1px solid #ddd;
	font-family: 'Georgia', serif;
	font-size: 14px;
	padding: 15px;
}

.RichEditor-editor {
	border-top: 1px solid #ddd;
	cursor: text;
	font-size: 16px;
	margin-top: 10px;
}

.RichEditor-editor .public-DraftEditorPlaceholder-root,
.RichEditor-editor .public-DraftEditor-content {
	margin: 0 -15px -15px;
	padding: 15px;
}

.RichEditor-editor .public-DraftEditor-content {
	min-height: 100px;
}

.RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
	display: none;
}

.RichEditor-editor .RichEditor-blockquote {
	border-left: 5px solid #eee;
	color: #666;
	font-family: 'Hoefler Text', 'Georgia', serif;
	font-style: italic;
	margin: 16px 0;
	padding: 10px 20px;
}

.RichEditor-editor .public-DraftStyleDefault-pre {
	background-color: rgba(0, 0, 0, 0.05);
	font-family: 'Inconsolata', 'Menlo', 'Consolas', monospace;
	font-size: 16px;
	padding: 20px;
}

.RichEditor-controls {
	font-family: 'Helvetica', sans-serif;
	font-size: 14px;
	margin-bottom: 5px;
	user-select: none;
}

.align-left > .public-DraftStyleDefault-ltr {
	text-align: left;
}
.align-center > .public-DraftStyleDefault-ltr {
	text-align: center;
}
.align-right > .public-DraftStyleDefault-ltr {
	text-align: right;
}
.align-justify > .public-DraftStyleDefault-ltr {
	text-align: justify;
}

@media (max-width: 685px) and (-webkit-min-device-pixel-ratio: 0) {
	select:focus,
	textarea:focus,
	input:focus {
		font-size: 16px;
	}
}

/* Laptop */
@media (max-width: 1550px) {
	html {
		font-size: 8px;
	}
}
@media (max-width: 1024px) {
	html {
		font-size: 7px;
	}
}

/* Tablet */
@media (max-width: 900px) {
	.title {
		align-self: center;
		text-align: center;
	}
}

/* Phone */
@media (max-width: 685px) {
	html {
		font-size: 10px;
	}
	.title {
		/* margin: 0 0 3.2rem; */
		font-size: 2.4rem;
	}
}
