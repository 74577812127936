div.container {
	margin: 0 0 4rem;
	align-items: normal;
}

/* Tablet */
@media (max-width: 900px) {
	/* first red service */
	.service:nth-child(1) > div:nth-of-type(1) {
		background: var(--gradient2);
	}
	.service:nth-child(1) > div::after {
		background-color: rgba(239, 74, 74, 0.7);
	}
	.service:nth-child(1) > p {
		text-transform: uppercase;
	}
	.service:nth-child(1) > div > svg {
		fill: #fff;
	}
}

/* Phone */
@media (max-width: 685px) {
	div.container {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		justify-items: center;
		row-gap: 3.2rem;
	}
	.container > div:last-child {
		display: none;
	}
}
