.container {
	padding: 10px;
	min-height: 70vh;
	box-shadow: 2px 2px 14px rgba(0, 0, 0, 0.25);
	color: black;
}

.contentBlock {
	/* background-color: #fff; */
}
