.userInfo {
	display: grid;
	grid-template-columns: 50% 50%;

	& > div:nth-child(1) {
		padding: 0 4rem 0 0;
		display: flex;
		flex-direction: column;
		gap: 3rem;
		justify-content: space-between;
		border-right: 1px solid var(--color8);
	}
	& > div:nth-child(2) {
		padding: 0 0 0 4rem;
	}
}
.infoBlock {
	display: flex;
	flex-direction: column;
	gap: 3rem;
}

.titleBlock {
	/* margin: 0 0 1.875rem; */
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.editBtn {
	display: flex;
	align-items: center;
	&:hover {
		cursor: pointer;

		& > svg > g > path {
			fill: var(--color3);
		}
	}
	&:active {
		& > svg > g > path {
			fill: var(--color8);
		}
	}

	& > svg {
		width: 3rem;
		height: 3rem;

		& > g > path {
			fill: var(--color8);
		}
	}
}

.inputBlockList {
	display: flex;
	flex-direction: column;
	gap: 2rem;
}

.form {
	max-width: 95vw;
	width: 60rem;
	box-sizing: border-box;

	&__inputList {
		display: flex;
		flex-direction: column;
		gap: 1rem;
		width: 100%;
	}
}
.file {
	display: flex;
	flex-direction: column;
	text-align: start;
	gap: 1rem;
	width: 100%;
}

.input,
.btn {
	padding: 1.6rem 3.2rem;
	text-align: center;
	font-size: 1em;
	border-radius: 4.2rem;
}

.error {
	color: var(--color4);
}

@media (max-width: 900px) {
	.title {
		font-size: 1.25em;
	}
	.edit > p {
		display: none;
	}
	.edit > svg {
		width: 2em;
		height: 2em;
	}

	.info {
		grid-template-columns: 1fr;
	}
}

/* Phone */
@media (max-width: 685px) {
	.userInfo {
		grid-template-columns: 1fr;
		gap: 3rem;

		& > div:nth-child(1),
		div:nth-child(2) {
			padding: 0;
			border-right: none;
		}
	}
}
