.container {
	margin: 0 0 10rem;
	display: flex;
	flex-direction: column;
	gap: 3rem;
}

/* Tablet */
@media (max-width: 900px) {
	.container {
		display: flex;
		flex-direction: column;
	}
}
