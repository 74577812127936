.form {
	padding: 2.6rem 3rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	gap: 2.6rem;
	/* background-color: #ffffff; */
	/* border: 1px solid var(--color5); */
	/* border-radius: 1.625rem; */
	/* box-sizing: border-box; */
}

.title {
	margin: 0;
	font-size: 2.5rem;
	/* font-size: 28px; */
	font-weight: 500;
}
