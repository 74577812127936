.container {
}

.userInfoBlock {
	margin: 0 0 6rem;
	display: grid;
	grid-template-columns: 70% 30%;
}

.subscription {
	display: flex;
	flex-direction: column;
	justify-self: flex-end;
	gap: 3.2rem;
	width: 24.5rem;
}

.card {
	padding: 1.2rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 4px;
	width: 100%;
	border-radius: 20px;
	background-color: var(--color6);
	box-sizing: border-box;
}

.card > p:nth-of-type(2) {
	font-weight: 500;
}
.card > p:nth-of-type(3) {
	color: var(--color3);
	font-size: 1.4rem;
}

.payBlock {
	display: grid;
	grid-template-columns: 55% 40%;
	justify-content: space-between;
}

.payBlock > div {
	display: flex;
	flex-direction: column;
	gap: 4rem;
}
