.container {
	margin: 0 0 10rem;
	display: flex;
	flex-direction: column;
	gap: 3rem;
}

.wrp {
	display: grid;
	grid-template-columns: 50% 45%;
	justify-content: space-between;
}

.bigCard {
	display: flex;
	flex-direction: column;
	gap: 2rem;
	padding: 3rem 1.5rem 3rem 3rem;
	border: 1px solid #d1d1d1;
	border-radius: 2rem;
	transform: translateY(0px);
	transition: all 500ms;

	&:hover {
		cursor: pointer;
		transform: translateY(-1rem);
		box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.21);
	}

	& > div {
		display: flex;
		gap: 2.4rem;
	}

	& > p {
		font-size: 1.125em;
	}

	& > div > img {
		width: 50%;
		border-radius: 1.5rem;
	}
}

.cardInfo {
	display: grid;

	& > cite {
		align-self: end;
	}

	& > h4 {
		font-size: 1.5em;
		font-weight: 500;
		/* line-height: 2.6rem; */
	}
}

.data,
.cardInfo > cite {
	justify-self: flex-end;
	font-size: 0.875em;
}

.cards {
	padding: 0 1rem 0 0;
	display: flex;
	flex-direction: column;
	gap: 0.8rem;
	max-height: 440px;
	overflow-y: auto;
	box-sizing: border-box;

	&::-webkit-scrollbar,
	&::-webkit-scrollbar-thumb {
		width: 0.5rem;
		background-color: var(--color6);
		border-radius: 0.5rem;
	}

	&::-webkit-scrollbar-thumb {
		background-color: var(--color5);
	}
}

/* small card */

.smallCard {
	display: flex;
	gap: 2rem;
	font-size: 1.6rem;
	border-radius: 2rem;
	border: 1px solid #d1d1d1;
	transform: translateY(0px);
	transition: all 500ms;

	&:hover {
		cursor: pointer;
		transform: translateY(-1rem);
		box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.21);
	}

	&:nth-of-type(1):hover {
		transform: translateY(0.8rem);
	}

	& > img {
		width: 20%;
		border-radius: 2rem;
	}

	& > div {
		padding: 2rem 1.5rem 1rem 0;
		display: grid;
		justify-content: space-between;
	}

	& > div > h6 {
		font-size: 1em;
		font-weight: 500;
		/* line-height: 1.8rem; */
	}
}

/* Laptop */
@media (max-width: 1550px) {
	.cards {
		max-height: 361px;
	}
}
@media (max-width: 1024px) {
	.smallCard {
		/* font-size: 1rem; */
	}
	.cards {
		max-height: 312px;
	}
}

/* Tablet */
@media (max-width: 900px) {
	.container {
		padding: 0 5rem;
		display: flex;
		flex-direction: column;
		align-items: center;
		font-size: 14px;
	}
	.wrp {
		grid-template-columns: 1fr;
		gap: 0.6rem;
	}
	.bigCard > div > img {
		width: 35%;
		border-radius: 1.5rem;
	}
	.cardInfo > h4 {
		line-height: 1em;
	}
	.cards {
		gap: 0.6rem;
	}
	.smallCard {
		font-size: 1em;

		& > div > h6 {
			line-height: 1em;
		}
	}
}

/* Phone */
@media (max-width: 685px) {
	.container {
		padding: 0;
		/* font-size: 12px; */
	}
	.wrp {
		gap: 1.6rem;
	}
	.bigCard {
		padding: 0 0 1.6rem;
		border: none;
		border-radius: 0;
		border-bottom: 1px solid var(--color5);
		&:hover {
			cursor: auto;
			transform: none;
			box-shadow: none;
		}
		& > img {
			width: 100%;
		}
		& > p {
			font-size: 1.6rem;
		}
		& > div {
			/* gap: 0; */
			justify-content: space-between;
			align-items: center;
		}
		& > div > div > cite {
			font-size: 1.6rem;
		}
		& > div > button {
			width: auto;
		}
	}
	.cards {
		gap: 1.6rem;
	}
	.smallCard {
		padding: 0 0 1.6rem;
		flex-direction: column;
		gap: 0.8rem;
		border: none;
		border-radius: 0;
		border-bottom: 1px solid var(--color5);
		&:hover {
			cursor: auto;
			transform: none;
			box-shadow: none;
		}
		& > h6 {
			font-size: 1em;
		}
		& > div {
			padding: 0;
			display: flex;
			gap: 2.4rem;
		}
		& > div > button {
			width: auto;
		}
	}
}
