.container {
	padding: 3rem 3rem 2rem 2.5rem;
	display: flex;
	flex-direction: column;
	gap: 2rem;
	width: 100%;
	background-color: var(--color6);
	border-radius: 2rem;
	box-sizing: border-box;
}

.titleBlock {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.title {
	font-size: 2rem;
	font-weight: 500;
	line-height: normal;
	text-transform: uppercase;
}

.history {
	font-size: 1.4rem;
	color: var(--color3);
}

.table {
	padding: 0 5px 0 0;
	display: flex;
	flex-direction: column;
	height: 23.8rem;
	overflow-y: auto;
	box-sizing: border-box;
}
.table::-webkit-scrollbar,
.table::-webkit-scrollbar-thumb {
	width: 4px;
	background-color: #e7e7e7;
	border-radius: 8px;
}
.table::-webkit-scrollbar-thumb {
	background-color: var(--color5);
}

.tableHead,
.row {
	display: grid;
	grid-template-columns: 2fr 1fr 1fr;
	font-size: 1.4rem;
	border-bottom: 1px solid #dbdbdb;
	box-sizing: border-box;
}
.row {
	border-bottom: none;
}
.row:last-of-type {
	flex: 1;
}

.cell {
	padding: 1.4rem;
	border-right: 1px solid #dbdbdb;
}

.tableHead > .cell:first-child,
.row > .cell:first-child {
	padding: 1.4rem 1.4rem 0rem 0;
}

.tableHead > .cell:last-child,
.row > .cell:last-child {
	border-right: none;
}

@media (max-width: 900px) {
	.container {
		font-size: 16px;
	}
	.title {
		font-size: 2em;
	}
	.table {
		height: auto;
		overflow-y: auto;
	}
	.history {
		font-size: 1.4em;
	}
	.tableHead {
		display: none;
	}
	.row {
		padding: 1.4em 0;
		grid-template-columns: 1fr;
		gap: 1em;
		font-size: 1.6em;
		border-bottom: 1px solid #dbdbdb;
	}
	.row > .cell:first-child {
		padding: 0;
	}
	.cell {
		padding: 0;
		border-right: none;
	}
}
