.container {
	max-width: max-content;
	border-radius: 1.6rem;
	background-color: var(--color6);
}

.container > div {
	padding: 9rem 1.6rem 1.2rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 6.8rem;
}

.container > div > p {
	text-align: center;
	max-width: 230px;
	font-size: 1rem;
}

@media (max-width: 900px) {
	.container {
		font-size: 16px;
		background-color: #fff;
	}
	.container > div > p {
		font-size: 10px;
	}
}
