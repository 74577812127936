.appeal {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	box-sizing: border-box;
}

.appealHead {
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: 20px;
	font-weight: 500;
}
.appealHead > p:last-of-type {
	font-size: 1.2rem;
	font-weight: 400;
}

.subtitle {
	color: #838383;
	font-weight: 500;
}

textarea {
	padding: 1rem 2rem;
	/* font-size: 1.4rem; */
	border: 1px solid #bcc2c7;
	border-radius: 1.6rem;
	box-sizing: border-box;
}
textarea::placeholder {
	color: #bcc2c7;
}

.fileContainer {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.file {
	display: flex;
	align-items: center;
	gap: 1rem;
	font-size: 1.4rem;
	font-weight: 500;
}
.file > input {
	position: absolute;
	visibility: hidden;
}
.btn {
	padding: 0.8rem 4.8rem;
	/* min-width: 100%; */
	width: max-content;
	gap: 0.8rem;
	font-size: 1.5rem;
	line-height: normal;
}
/* .appealLastRow {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.appealLastRow > button {
	padding: 0;
	color: var(--color3);
	background-color: transparent;
} */
