.container {
	/* height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center; */
	touch-action: none;
}

.signature {
	width: 320px;
	height: 160px;
	border: 1px solid #d3d3d3;
	box-sizing: border-box;
}

.btnContainer {
	display: grid;
	grid-template-columns: 1fr 1fr;
	column-gap: 20px;
}
