/* .container {
	margin: 6.875rem 0 0;
} */

.bgr {
	margin: 0 0 5.2rem;
	padding: 2.4rem 0 4rem;
	background-color: #f6f9fb;
}

.bgrFlag {
	position: relative;
	z-index: 1;
}
.bgrFlagImg {
	position: absolute;
	top: 0;
	right: 0;
	width: 60%;
	z-index: -1;
}

/* Tablet */
@media (max-width: 900px) {
	.bgrFlagImg {
		width: 100%;
		height: 100%;
	}
}

/* Phone */
@media (max-width: 685px) {
	.container {
		margin: 0;
	}
	.bgrFlagImg {
		height: auto;
	}
}
