.btnMenu {
	display: flex;
	align-items: center;
	position: relative;
}
.btnMenu > p {
	font-size: 1.1em;
	/* font-size: 18px; */
	/* line-height: 30px; */
}

#menuToggle {
	display: none;
}
.menuToggleLabel {
	position: absolute;
	height: 100%;
	width: 100%;
	cursor: pointer;
	z-index: 1;
}

.burgerMenu,
.burgerMenu::before,
.burgerMenu::after {
	display: block;
	height: 0.4rem;
	width: 3.8rem;
	transition: transform 1s cubic-bezier(0.23, 1, 0.32, 1);
	background-color: #0a42d3;
	border-radius: 50px;
}
.burgerMenu {
	margin: 0 3rem 0 0;
	width: 2.5rem;
	position: relative;
}
.burgerMenu::before {
	content: '';
	position: absolute;
	bottom: 1.1rem;
}
.burgerMenu::after {
	content: '';
	position: absolute;
	top: 1.1rem;
}
#menuToggle:checked ~ .burgerMenu::before {
	bottom: 0;
	transform: rotate(135deg);
}
#menuToggle:checked ~ .burgerMenu {
	background: transparent;
}
#menuToggle:checked ~ .burgerMenu::after {
	top: 0;
	transform: rotate(-135deg);
}
