header {
	padding: 1.2rem 0 0;
	position: absolute;
	top: 0;
	background-color: #fff;
	width: 100%;
	z-index: 2;
}
.container {
	margin: 0 auto 1.3rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	max-width: 1440px;
	box-sizing: border-box;
}

.leftBlock {
	display: flex;
	align-items: center;
}
.rightBlock {
	display: flex;
	gap: 20px;
	align-items: center;
}
.icon {
	width: 3rem;
	height: 3rem;
	fill: var(--color5);
}

.btns {
	margin: 0 auto 0.5rem;
	display: grid;
	grid-template-columns: repeat(5, 1fr);
}
.btns > div > svg {
	width: 3rem;
	height: 3rem;
}
.btns > div > svg > g > path[fill] {
	fill: url(#Gradient);
}

.loginBtn {
	padding: 0;
	display: flex;
	align-items: center;
	gap: 10px;
	font-size: 1.1em;
	/* font-size: 18px; */
	font-weight: 500;
	background-color: #fff;
	border: none;
	box-sizing: border-box;
}

.loginBtn:hover {
	cursor: pointer;
	color: var(--color3);
}
.loginBtn:hover svg {
	fill: var(--color3);
}
.loginBtn:active {
	color: var(--color1);
}
.loginBtn:active svg {
	fill: var(--color5);
}

.categoriesMenuContainer {
	max-height: 0;
	overflow: hidden;
	transition: 1.5s max-height;
}
/* Laptop */
@media (max-width: 1550px) {
	.container {
		max-width: 1000px;
	}
}

@media (max-width: 1024px) {
	.container {
		max-width: 850px;
	}
}

/* Tablet */
@media (max-width: 900px) {
	.container {
		max-width: 665px;
	}
}
