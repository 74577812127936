.list {
	margin: 4.5rem 0 0;
	padding: 0;
	display: grid;
	grid-template-columns: repeat(2, max-content);
	justify-content: space-between;
	gap: 2rem;
	list-style: none;
}

.link {
	padding: 1rem 1.5rem;
	display: flex;
	align-items: center;
	gap: 1.5rem;
	width: max-content;
	color: var(--color3);
	/* font-size: 1.8rem; */
	border-radius: 2.4rem;
	border: 1px solid #d1d1d1;
	box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.05);
	box-sizing: border-box;
	transform: translateY(0px);
	transition: all 500ms;
}
.link:hover {
	transform: translateY(-10px);
	box-shadow: 1px 1px 14px 0px rgba(0, 0, 0, 0.25);
}

.link > img {
	width: 4.5rem;
}

/* Laptop */
@media (max-width: 900px) {
	.list {
		gap: 1rem;
	}
}

/* Tablet */
@media (max-width: 900px) {
	.list {
		margin: 0;
	}
	.link {
		font-size: 13px;
	}
	.link > img {
		width: 5.6rem;
	}
}

/* Phone */
@media (max-width: 685px) {
	.list {
		display: flex;
		flex-direction: column;
		width: 100%;
	}
	.link {
		width: 100%;
		/* justify-content: space-between; */
	}
	.link > svg {
		margin: 0 0 0 auto;
	}
}
