.container {
	display: flex;
	flex-direction: column;
	gap: 3rem;
}

.title {
	/* margin: 0 0 2rem; */
	font-size: 2rem;
	font-weight: 500;
	line-height: normal;
	text-transform: uppercase;
}

.list {
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	gap: 1.4rem;
}

.card {
	padding: 1.4rem 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	border-radius: 2rem;
	background-color: var(--color6);
}

.card > p:nth-of-type(1) {
	font-size: 2rem;
}
.card > p:nth-of-type(2) {
	font-size: 2rem;
	font-weight: 500;
}
.card > p:nth-of-type(3) {
	color: var(--color3);
}

@media (max-width: 900px) {
	.title {
		font-size: 20px;
	}

	.list {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
	}
	.card {
		max-width: 160px;
		background-color: #fff;
		font-size: 12px;
	}
	.card > p:nth-of-type(1) {
		font-size: 16px;
	}
	.card > p:nth-of-type(2) {
		font-size: 14px;
	}
}
