.container {
	display: flex;
	justify-content: space-between;
	width: 100%;
	border: 1px solid var(--color5);
	border-radius: 30px;
	box-sizing: border-box;
}

.input {
	margin: 0;
	padding: 0 15px;
	font-size: 1.6rem;
	border: none;
}

.btn {
	padding: 1rem 2rem;
	width: max-content;
	gap: 1rem;
	white-space: nowrap;
	color: #fff;
	background: var(--gradient1);
	border: none;
	border-radius: 0 2.8rem 2.8rem 0;
}
/* .btn > svg > path {
	fill: #fff;
} */

.btn:hover {
	color: var(--color3);
	border-left: 1px solid var(--color5);
	background: #fff;
}
