.green,
.red {
	/* margin: 5px 0; */
	padding: 0;
	width: 28px;
	/* height: 26px;  */
	/* font-size: 1.6rem; */
	font-size: 26px;
	line-height: 26px;
	background-color: transparent;
	border-radius: 50%;
	box-sizing: border-box;
}
.green {
	margin: 0px 10px 0px 0;
	color: #0c5700;
	border: 1px solid #0c5700;
}
.red {
	color: #d52b1e;
	border: 1px solid #d52b1e;
}

.green:hover,
.red:hover {
	cursor: pointer;
	color: #fff;
}
.green:hover {
	background-color: #0c5700;
}
.red:hover {
	background-color: #d52b1e;
}

.green:active,
.red:active {
	cursor: pointer;
	background-color: transparent;
}
.green:active {
	color: #0c5700;
}
.red:active {
	color: #d52b1e;
}
