.form {
	width: auto;
	height: min-content;
	justify-self: center;
	align-self: center;
	align-items: normal;
}

.label {
	margin: 0 0 15px;
}

.listContainer {
	position: relative;
}

.list {
	position: absolute;
	margin: 0;
	padding: 0;
	top: 64px;
	width: 100%;
	max-height: 30vh;
	overflow-y: auto;
	list-style-type: none;
	background-color: var(--color-white);
	border-radius: 0 0 4px 8px;
	/* border: 1px solid var(--main-color); */
	box-sizing: border-box;
	box-shadow: 2px 2px 14px rgba(0, 0, 0, 0.25);
	z-index: 1;
}

.list::-webkit-scrollbar {
	width: 2px;
	background-color: #e7e7e7;
	border-radius: 2px;
}
.list::-webkit-scrollbar-thumb {
	width: 2px;
	background-color: var(--main-color);
	border-radius: 2px;
}

.input {
	margin: 5px 0 0;
}

.qrContainer {
	margin: 30px 40px;
	display: flex;
	flex-direction: column;
	align-items: center;
}
.btnContainer {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 10px;
}

/* .signatureBtnContainer {
	display: grid;
	grid-template-columns: 1fr 1fr;
	column-gap: 20px;
} */

/* .signature {
	width: 320px;
	height: 160px;
	border: 1px solid #d3d3d3;
	box-sizing: border-box;
} */
