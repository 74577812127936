.options {
	display: flex;
	/* justify-content: flex-start; */
}

.tab {
	padding: 5px 20px;
	font-size: 18px;
	background-color: inherit;
	border-radius: 10px 10px 0 0;
}
.tab[aria-selected='true'] {
	background-color: var(--color7);
}
.tab:hover {
	cursor: pointer;
}

.content {
	background-color: var(--color7);
	border-radius: 10px;
}

.tabPanel {
	padding: 10px 20px;
	display: flex;
	gap: 20px;
	flex-wrap: wrap;
}

.btn {
	padding: 8px 10px;
	display: flex;
	align-items: center;
	gap: 10px;
	font-size: 14px;
	background-color: #fff;
	border-radius: 6px;
}
.btn:hover {
	cursor: pointer;
}

.btn > svg {
	width: 21px;
	height: 21px;
}
.btn > svg > path {
	fill: var(--color1);
}
