.container {
	margin: 0 0 3.2rem;
	display: flex;
	justify-content: space-between;
	/* align-items: center; */
	font-size: 2rem;
}

.logo {
	margin: 0 0 0.8rem;
	font-family: 'Snell-Roundhand';
	font-size: 7.2rem;
	font-style: normal;
	font-weight: 700;
	line-height: 7.2rem;
}
.logo > span {
	font-size: 13.2rem;
	color: #d52b1e;
}

.titleBlock > p:nth-of-type(2) {
	/* margin: 0 0 0.5rem; */
	font-family: Georgia;
	font-size: 4.8rem;
}
.titleBlock > p:nth-of-type(3) {
	margin: 0 0 3.2rem;
	font-size: 2.4rem;
}

.iconsBlock {
	/* margin: 0 0 0.5rem; */
	display: flex;
	/* width: 85%; */
	gap: 3.2rem;
	/* justify-content: space-between; */
	font-size: 1.4rem;
}

.iconsBlock > div {
	display: flex;
	gap: 1.2rem;
	align-items: center;
}

.iconsBlock > div > svg {
	width: 3rem;
	height: 3rem;
}

.iconsBlock > div > svg > g > path[fill] {
	fill: url(#Gradient);
}

.infoCards {
	/* margin: 2.5rem 0 0; */
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	/* gap: 2rem; */
}

.infoCards > div {
	margin: 1.6rem 0 0;
	padding: 2rem 4rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 0.8rem;
	font-size: 1.6rem;
	font-weight: 500;
	border-radius: 10px;
	background-color: #fff;
}

.infoCards > div > p:nth-of-type(2) {
	font-size: 1.2rem;
	font-weight: 400;
}

.link {
	display: flex;
	align-items: center;
	gap: 0.8rem;
	font-size: 1.5rem;
	color: var(--color3);
}
.link > svg {
	height: 1.8rem;
	width: max-content;
}

/* Tablet */
@media (max-width: 900px) {
	.container {
		flex-direction: column;
	}
	.titleBlock {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	.infoCards {
		flex-direction: row;
		justify-content: center;
		gap: 1.6rem;
	}
	.infoCards > div {
		justify-content: center;
	}
}

/* Phone */
@media (max-width: 685px) {
	.container {
		align-items: center;
		gap: 1.6rem;
	}
	.titleBlock {
		max-width: 47.5rem;
		width: 100%;
		text-align: center;
	}
	.logo {
		display: none;
	}
	.titleBlock > p:nth-of-type(2) {
		font-size: 2.4rem;
		font-weight: 700;
	}
	.titleBlock > p:nth-of-type(3) {
		margin: 0 0 2.4rem;
		font-size: 1.6rem;
	}
	.iconsBlock {
		justify-content: space-between;
		width: 100%;
	}
	.iconsBlock > div {
		flex-direction: column;
	}
	.infoCards {
		flex-direction: column;
	}
	.infoCards > div {
		width: 100%;
		text-align: center;
		font-size: 2rem;
		box-sizing: border-box;
	}
	.infoCards > div > p:nth-of-type(2) {
		font-size: 1.5rem;
	}
}
