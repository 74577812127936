.img {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 9.6rem;
	height: 9.6rem;
	background: var(--gradient1);
	border-radius: 50%;
	box-sizing: border-box;
	position: relative;
}
.img::after {
	content: '';
	position: absolute;
	width: 4rem;
	height: 0.4rem;
	bottom: -1.1rem;
	left: 50%;
	transform: translate(-50%);
	margin-right: -50%;
	background-color: rgba(74, 130, 239, 0.7);
	filter: blur(0.2rem);
	border-radius: 50%;
}
