.container {
	/* padding: 0.75rem 0 0; */
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	height: 100%;
	box-sizing: border-box;
}

main {
	margin: 12.2rem 0 0;
	display: flex;
	flex-direction: column;
	flex: 1;
}

/* Laptop */
@media (max-width: 1550px) {
	.container {
		height: 100vh;
		/* overflow-y: scroll; */
	}
}

/* Phone */
@media (max-width: 685px) {
	.container {
		padding: 0;
		/* height: 100vh; */
		/* overflow-y: scroll; */
		/* overflow-x: hidden; */
	}

	main {
		margin: 0;
	}
}
