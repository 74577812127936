.textarea {
	width: 100%;
	/* font-size: 1.6rem; */
	line-height: normal;
	color: var(--color1);
	border-radius: 4px;
	border: 1px solid var(--color5);
	box-sizing: border-box;
	resize: none;
}
