.container {
	display: flex;
	flex-direction: column;
	gap: 1rem;
}

.btn {
	padding: 1.6rem 2.4rem;
	justify-content: space-between;
	font-size: 1.8rem;
	color: var(--color1);
	background: #fff;
	border: 1px solid #d1d1d1;
	border-radius: 4.5rem;
	transform: translateY(0);
	transition: all 500ms ease;

	&:hover {
		width: 105%;
		// height: 125%;
		font-size: 2rem;
		color: var(--color1);
		background: #fff;
		box-shadow: 1px 1px 14px 0px rgba(0, 0, 0, 0.25);
		transform: translateY(-0.8rem);
	}

	& > svg {
		width: 4.5rem;
		height: 3rem;
	}
}

.info {
	// padding: 5rem 2rem;
	border-radius: 3rem;
	// background-color: #fff;
	// box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.35);
	box-sizing: border-box;
	transition: all 1s;
}
