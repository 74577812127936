.container {
	display: flex;
	flex-direction: column;
	gap: 1.875em;
}

.headRow {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.headRow > p {
	color: var(--color3);
	font-size: 0.875em;
}

.title {
	font-size: 1.25em;
	font-weight: 500;
	line-height: normal;
	text-transform: uppercase;
}

.list {
	display: flex;
	flex-direction: column;
	gap: 10px;
}

/* ServiceInfo */

.serviceInfo {
	display: flex;
	flex-direction: column;
	gap: 20px;
	font-size: 18px;
}
.serviceTitleBlock {
	display: flex;
	flex-direction: column;
	gap: 4px;
}
.serviceTitleBlock > h5 {
	font-size: 14px;
	color: var(--color3);
}

.serviceStatus {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}
.serviceStatus > div {
	font-size: 14px;
}
.serviceStatus > div:first-of-type {
	flex-direction: row;
	justify-content: flex-start;
	flex-wrap: wrap;
}
.serviceStatus > div:first-of-type > p {
	border-radius: 20px;
}
.serviceStatus > div > button {
	padding: 8px 15px;
	font-size: 14px;
}

@media (max-width: 550px) {
	.title {
		font-size: 1em;
	}
}
