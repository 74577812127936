.container {
	display: flex;
	flex-direction: column;
	gap: 1.875em;
}

.title {
	font-size: 2rem;
	font-weight: 500;
	line-height: normal;
	text-transform: uppercase;
}

.payMethod {
	display: flex;
	justify-content: space-between;
	gap: 2rem;
}

.paymentMethodCard {
	padding: 1.2rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 4px;
	width: 100%;
	max-width: 130px;
	border-radius: 2rem;
	background-color: var(--color6);
	box-sizing: border-box;
}

.paymentMethodCard > svg {
	width: 4.2rem;
	height: 4.2rem;
}
.paymentMethodCard:nth-of-type(3) > div {
	display: flex;
	gap: 0.6rem;
}
.paymentMethodCard:nth-of-type(3) > div > svg {
	width: 2.8rem;
	height: 5rem;
}
.paymentMethodCard:nth-of-type(4) > svg {
	width: 8.2rem;
	height: 4.4rem;
}

.paymentMethodCard > svg > g > path {
	fill: url('#Gradient');
	/* stroke: url('#Gradient'); */
}

.paymentMethodCard:nth-of-type(5) > svg > g > path {
	fill: none;
	stroke: url('#Gradient');
}

.cardName {
	font-size: 1.2rem;
	line-height: 1.2rem;
}
.paymentMethodCardActive {
	background-color: #fff;
	box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.35);
}

.payContent {
	display: flex;
	flex-direction: column;
	gap: 2.8rem;
}

.order {
	display: flex;
	flex-direction: column;
	width: 70%;
}

.order > span {
	font-size: 1rem;
}
.order > p {
	align-self: flex-end;
}

@media (max-width: 900px) {
	.container {
		padding: 0 0 50px;
	}

	.title {
		font-size: 1.25em;
	}

	.payMethod {
		/* justify-content: flex-start; */
		flex-wrap: wrap;
	}
	.paymentMethodCard {
		/* font-size: 16px; */
		background-color: #fff;
	}
	.paymentMethodCard > svg {
		width: 45px;
		height: 45px;
	}
	.paymentMethodCard:nth-of-type(3) > div > svg {
		width: 25px;
		height: 45px;
	}
	.paymentMethodCard:nth-of-type(4) > svg {
		width: 60px;
		height: 45px;
	}
	.cardName {
		font-size: 12px;
		line-height: 12px;
	}
	.inputWithBtnWrp {
		background-color: #fff;
	}
	.inputWithBtn {
		font-size: 16px;
	}
	.order {
	}
	.order > span {
		font-size: 10px;
	}
}

@media (max-width: 450px) {
	.order {
		width: 100%;
	}
}
