.RichEditorStyleButton 
	/* RichEditor-styleButton */ {
	/* color: #999; */
	color: var(--light-color-blue);
	cursor: pointer;
	margin-right: 16px;
	padding: 2px 0;
	display: inline-block;
}

.RichEditorActiveButton 
	/* RichEditor-activeButton */ {
	/* color: #5890ff; */
	color: var(--main-color);
}
