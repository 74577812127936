.container {
	display: flex;
	flex-direction: column;
	gap: 3.2rem;
}
.headRow {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.headRow > div {
	display: flex;
	align-items: center;
	gap: 2rem;
}
.headRow > div:last-of-type {
	color: var(--color3);
	font-size: 1.4rem;
}

.title {
	font-size: 2rem;
	font-weight: 500;
	line-height: normal;
	text-transform: uppercase;
}

.btnBack {
	margin: 0;
	padding: 0;
	display: flex;
	align-items: center;
	gap: 10px;
	width: max-content;
	font-size: 1.8rem;
	line-height: normal;
	background-color: inherit;
}
.btnBack:hover {
	cursor: pointer;
}

.search {
	padding: 0.6rem 1.6rem;
	display: flex;
	align-items: center;
	border-radius: 2.8rem;
	background-color: #f6f6f6;
	box-sizing: border-box;
}
.search > input {
	border: none;
}
.search > input::placeholder {
	color: var(--color5);
}
.search > svg {
	width: 2rem;
	height: 2rem;
}
.search > svg > path {
	fill: var(--color5);
}

.table {
	display: flex;
	flex-direction: column;
}

.headRow {
	font-size: 18px;
}

.row {
	padding: 10px 0;
	display: grid;
	grid-template-columns: 3fr 1fr 1fr 2fr 1fr 1fr 1fr;
	justify-items: flex-start;
	align-items: center;
	border-bottom: 1px solid var(--color5);
	box-sizing: border-box;
}
.row > td:last-of-type,
.row > th:last-of-type {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
}

.row > td:last-of-type > div:hover {
	cursor: pointer;
}
.menu,
.menu::after,
.menu::before {
	position: relative;
	margin: 0 1rem;
	content: '';
	width: 0.8rem;
	height: 0.8rem;
	top: 0px;
	right: 0px;
	border-radius: 50%;
	background-color: var(--color3);
}
.menu::after {
	position: absolute;
	margin: 0;
	top: 0px;
	right: -1rem;
}
.menu::before {
	position: absolute;
	margin: 0;
	top: 0px;
	right: 1rem;
}

.menuList {
	position: absolute;
	padding: 1rem;
	top: 1rem;
	right: 2rem;
	list-style-type: none;
	background-color: #fff;
	border-radius: 1rem;
	box-shadow: 0.854px 0.854px 33.42px 0px rgba(0, 0, 0, 0.35);
}
.menuList > li {
	padding: 0.6rem;
	width: max-content;
	border-radius: 1rem;
}
.menuList > li:hover {
	cursor: pointer;
	background-color: var(--color7);
}
