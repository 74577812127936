.headerMobile {
	padding: 1rem 0 0;
	position: sticky;
	top: 0;
}

.container {
	padding: 0 1.8rem;
	box-sizing: border-box;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.logo > svg {
	display: none;
}
.container > .logo > p {
	font-size: 3.2rem;
}
.container > .logo > p > span {
	font-size: 5.7rem;
	line-height: 2.4rem;
}

.menu {
	padding: 3.9rem 0 0;
	display: flex;
	flex-direction: column;
	gap: 2.7rem;
	height: calc(100vh - 59.5px - 73px); /* 100vh-headerMobile-bottomMenuMobile */
	width: 0%;
	right: 0;
	background-color: #fff;
	box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.35);
	box-sizing: border-box;
	transition: width 1s;
	position: absolute;
}

.item {
	padding: 0 0 0 3rem;
	flex-direction: row;
	gap: 10px;
	font-size: 14px;
	text-wrap: nowrap;
	border: none;
}
.item > svg {
	width: 3.6rem;
	height: 3.6rem;
}
.item > svg > g > path,
.item > svg > path {
	fill: url(#Gradient);
}

.socialNetwork {
	padding: 0 0 0 3rem;
	display: flex;
	gap: 1.2rem;
}
.socialNetwork > svg {
	width: 3.6rem;
	height: 3.6rem;
	transform: translateY(0);
	transition: all 500ms;
}
.socialNetwork > svg:hover {
	cursor: pointer;
	transform: translateY(-10px);
}

/* Small phone */
@media (max-width: 335px) {
	.container > .logo > p {
		font-size: 3rem;
	}
	.container > .logo > p > span {
		font-size: 5.7rem;
	}
}
