.container {
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 1.6rem 0.5rem;
}

.row {
	width: 100%;
	transform: translateY(0);
	transition: all 1s;
}

/* Search */
.search {
	padding: 1.6rem 5.6rem;
	display: flex;
	align-items: center;
	gap: 2.4rem;
	background: #fff;
	border-radius: 5.6rem;
	box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.21);

	& > svg:hover {
		cursor: pointer;
	}

	& > svg:nth-of-type(1) {
		width: 4.8rem;
		height: 4rem;
	}

	& > svg:nth-of-type(1) > path {
		stroke: #d3d3da;
	}

	& > svg:nth-of-type(2) {
		width: 4.8rem;
		height: 4rem;
		fill: #d3d3da;
	}
}

.input {
	font-size: 1.8rem;
	border: none;

	&::placeholder {
		color: #d3d3da;
	}
}

.line {
	width: 0.2rem;
	height: 4rem;
	background-color: #d3d3da;
}

/* Categories */
.categories {
	padding: 0 3rem;
	display: flex;
	justify-content: space-evenly;
	transform: translateY(0);
	transition: all 1s;

	& button {
		padding: 1rem 2rem;
		width: max-content;
	}
}

/* Laptop */
/* @media (max-width: 1550px) {
	.categories {
		padding: 0 1.5rem;
	}
	.categories button {
		padding: 1rem 1.5rem;
		font-size: 14px;
	}
} */
