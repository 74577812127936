.container {
	position: fixed;
	bottom: env(safe-area-inset-bottom);
	width: 100%;
	background-color: #fff;
	box-shadow: 0px -1px 4px 0px rgba(0, 0, 0, 0.25);
	z-index: 5;
}

.menu {
	padding: 5px 20px;
	display: flex;
	justify-content: space-between;
	/* align-items: center; */
}

.item {
	padding: 6px 0 0;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	gap: 3px;
}
.item:first-child {
	padding: 0;
}
.item > svg {
	width: 30px;
	height: 30px;
	fill: var(--color5);
}
.item:last-child > svg {
	fill: var(--color3);
}
.item > img {
	height: 30px;
}
.item > p {
	font-size: 10px;
}

.allCategories {
	width: 35px;
	height: 35px;
	background: var(--gradient2);
}
.allCategories::after {
	display: none;
}
.allCategories > svg {
	width: 22px;
	height: 22px;
	fill: #fff;
}
