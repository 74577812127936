.imgItem {
	font-family: 'Helvetica', sans-serif;
	color: var(--light-color-blue);
	cursor: pointer;
	margin-right: 16px;
	padding: 2px 0;
	display: inline-block;
}

.imgItemActive {
	color: var(--main-color);
}
