.container {
	display: flex;
	flex-direction: column;
	gap: 3rem;
}

.headRow {
	/* margin: 0 0 2rem; */
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.title {
	font-size: 2rem;
	font-weight: 500;
	line-height: normal;
	text-transform: uppercase;
}

.headRow > p {
	font-size: 1.4rem;
	color: var(--color3);
}

.list {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	gap: 1.2rem;
}

.serviceCard {
	width: 100%;
}

@media (max-width: 900px) {
	.container {
		padding: 0 0 50px;
	}
	.title {
		font-size: 20px;
	}
	.headRow > p {
		font-size: 14px;
	}
	.list {
		justify-items: center;
		grid-template-columns: repeat(2, 1fr);
	}
	.serviceCard {
		max-width: 330px;
	}
	.serviceCard > div:nth-of-type(2) > p:first-of-type {
		display: none;
	}
}

@media (max-width: 550px) {
	.serviceCard {
		/* min-width: 160px; */
		max-width: 200px;
	}
	.serviceCard > div:nth-of-type(1) {
		height: 100px;
	}
	.serviceCard > div:nth-of-type(2) {
		margin: -20px 0 0;
		font-size: 12px;
	}
	.serviceCard > svg {
		width: 20px;
		height: 20px;
		right: 15px;
	}
}

@media (max-width: 360px) {
	.list {
		grid-template-columns: 1fr;
		/* justify-items: center; */
	}
}
