.container {
	display: flex;
	flex-direction: column;
	gap: 3.2rem;
}
.headRow {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.headRow > div {
	display: flex;
	align-items: center;
	gap: 2rem;
}
.headRow > div:last-of-type {
	color: var(--color3);
	font-size: 1.4rem;
}
.headRow > div:last-of-type > button {
	padding: 0.6rem 1.6rem;
	width: auto;
	color: #fff;
	background: var(--gradient1);
}
.headRow > div:last-of-type > button > svg > path {
	fill: #fff;
}
.headRow > div:last-of-type > button:active {
	color: var(--color3);
	background: #fff;
}
.headRow > div:last-of-type > button:active > svg > path {
	fill: var(--color3);
}

.title {
	font-size: 2rem;
	font-weight: 500;
	line-height: normal;
	text-transform: uppercase;
}

.search {
	padding: 0.6rem 1.6rem;
	display: flex;
	align-items: center;
	border-radius: 2.6rem;
	background-color: #f6f6f6;
	box-sizing: border-box;
}
.search > input {
	border: none;
}
.search > input::placeholder {
	color: var(--color5);
}
.search > svg {
	width: 2rem;
	height: 2rem;
}
.search > svg > path {
	fill: var(--color5);
}

.content {
	display: flex;
	/* align-items: center; */
	gap: 20px;
	flex-wrap: wrap;
}

.card {
	padding: 1.6rem 2rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	gap: 1.6rem;
	width: 17rem;
	border-radius: 2.6rem;
	box-shadow: 0.854px 0.854px 3.416px 0px rgba(0, 0, 0, 0.35);
	box-sizing: border-box;
	position: relative;
}
.card > div > svg {
	width: 6rem;
	height: 6rem;
}
.card > div > svg > g > path {
	stroke: #fff;
}
.card:first-of-type > div > svg > g > path {
	fill: #fff;
}

.card:last-of-type {
	color: #989898;
	background-color: #f6f6f6;
}
.card:last-of-type > div:first-of-type {
	font-size: 50px;
	background: #fff;
}
.card:last-of-type > div:first-of-type:after {
	display: none;
}

.menu {
	position: absolute;
	top: 2rem;
	right: 0.8rem;
	transform: rotate(90deg);
}
.menu > div > ul {
	transform: rotate(-90deg);
	top: 4.8rem;
	right: -11.2rem;
}
