.container {
	display: flex;
	flex-direction: column;
	gap: 3.2rem;
}

.title {
	font-size: 2rem;
	font-weight: 500;
	line-height: normal;
	text-transform: uppercase;
}

.payBlock {
	display: flex;
	flex-direction: column;
	gap: 1.6rem;
}
.payData {
	display: grid;
	grid-template-columns: 60% 1fr;
	gap: 4.8rem;
	align-items: start;
}
