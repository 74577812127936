.container {
	padding: 30px 25px;
	display: flex;
	flex-direction: column;
	gap: 20px;
	background-color: var(--color7);
	border-radius: 20px;
	box-sizing: border-box;
}

.title {
	font-size: 2rem;
	font-weight: 500;
	line-height: normal;
	text-transform: uppercase;
}

.table {
	display: flex;
	flex-direction: column;
}

.row {
	padding: 10px 0;
	display: grid;
	grid-template-columns: 25% 10% 10% 15% 15% 15% 1fr;
	justify-items: flex-start;
	align-items: center;
	border-bottom: 1px solid var(--color5);
	box-sizing: border-box;
}
/* .row:last-of-type {
	border-bottom: none;
}
.row > th,
.row > td {
	padding: 10px;
	width: 100%;
	text-align: start;
	border-right: 1px solid var(--color5);
	box-sizing: border-box;
}
.row > th:last-of-type,
.row > td:last-of-type {
	border-right: none;
} */

.btn {
	width: 100%;
	border-radius: 3px;
	background-color: #fff;
	box-shadow: 0.854px 0.854px 3.416px 0px rgba(0, 0, 0, 0.35);
}
.btn:hover {
	cursor: pointer;
}
