.container {
	max-width: 1440px;
	font-size: 16px;
	height: 30em;
	position: relative;
	overflow: hidden;
}

.slider {
	position: absolute;
	height: 100%;
	top: 0px;
	left: 0px;
	display: flex;
	align-items: center;
	box-sizing: border-box;
}

/* Card */

div.card {
	position: absolute;
	z-index: 2;
}

/* .card {
	position: absolute;
	width: 450px;
	box-sizing: border-box;
	z-index: 2;
}
.card:hover {
	cursor: pointer;
}

.img {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 16.875em;
	background-repeat: no-repeat;
	background-size: cover;
	border-radius: 2em 2em 0 0;
	position: relative;
}
.card:hover .img::after {
	position: absolute;
	content: '';
	top: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(255, 255, 255, 0.7);
	backdrop-filter: blur(2px);
	border-radius: 2em 2em 0 0;
	box-sizing: border-box;
}

.img > button {
	display: none;
	width: max-content;
	color: var(--color1);
	border: none;
	z-index: 1;
}
.card:hover .img > button {
	display: block;
}

.info {
	margin: -2.2em 0 0;
	padding: 1.5em 1.5em 1em 1.9em;
	display: flex;
	flex-direction: column;
	color: var(--color2);
	line-height: normal;
	background: linear-gradient(180deg, #fdfdfc 0%, #f1eeed 54.24%, #efeded 100%);
	backdrop-filter: blur(1em);
	border-radius: 2em;
	position: relative;
}

.info > div {
	margin: 0 0 0.6em;
	display: flex;
	align-items: center;
	gap: 0.6em;
}
.info > div > svg {
	width: 3.8em;
	height: 3.8em;
}
.info > div > svg > g > path {
	fill: url(#Gradient);
}

.titleCard > h6 {
	color: var(--color3);
	font-size: 1em;
}

.titleCard > h4 {
	color: var(--color1);
	font-size: 1.25em;
}

.info > p:first-of-type {
	font-size: 1em;
	line-height: 1.5em;
}

.info > p:last-of-type {
	align-self: flex-end;
	font-size: 1.5em;
	font-weight: 500;
	color: var(--color3);
}

.card > svg {
	position: absolute;
	content: '';
	width: 2.2em;
	height: 2.2em;
	top: -0.25em;
	right: 1.9em;
	fill: #fff;
	filter: drop-shadow(1px 1px 0.25em rgba(0, 0, 0, 0.25));
}
.card > svg:hover {
	cursor: pointer;
}
.card > svg.active {
	fill: var(--color4);
} */

/* Small card */
div.smallCard {
	/* width: 280px; */
	position: absolute;
	z-index: 0;
}
/* 
.smallCard > .img {
	height: 9.7em;
}
.smallCard:hover .img::after {
	background-color: initial;
	backdrop-filter: none;
}
.smallCard:hover .img > button {
	display: none;
}

.smallCard > .info {
	padding: 0.9em 0.9em 0.6em 1.25em;
}

.smallCard > .info > div {
	margin: 0 0 0.3em;
	gap: 0.3em;
}

.smallCard > .info > div > svg {
	width: 2.5em;
	height: 2.5em;
}

.smallCard > .info > div > .titleCard > h6 {
	font-size: 0.7em;
}

.smallCard > .info > div > .titleCard > h4 {
	font-size: 0.8em;
}

.smallCard > .info > p:first-of-type {
	font-size: 0.7em;
	line-height: 1.1em;
}

.smallCard > .info > p:last-of-type {
	font-size: 1em;
}

.smallCard > svg {
	width: 1.4em;
	height: 1.4em;
	top: -0.2em;
	right: 1.25em;
} */

/* Middle card */
div.middleCard {
	/* width: 330px; */
	position: absolute;
	z-index: 1;
}

/* .middleCard:hover .img::after {
	background-color: initial;
	backdrop-filter: none;
}
.middleCard:hover .img > button {
	display: none;
}

.middleCard > .img {
	height: 12.5em;
}

.middleCard > .info {
	padding: 0.9em 0.9em 0.8em 1.375em;
}

.middleCard > .info > div {
	margin: 0 0 0.5em;
	gap: 0.5em;
}

.middleCard > .info > div > svg {
	width: 2.8em;
	height: 2.8em;
}

.middleCard > .info > div > .titleCard > h6 {
	font-size: 0.8em;
}

.middleCard > .info > div > .titleCard > h4 {
	font-size: 1em;
}

.middleCard > .info > p:first-of-type {
	font-size: 0.8em;
	line-height: 1em;
}

.middleCard > .info > p:last-of-type {
	font-size: 1.2em;
}

.middleCard > svg {
	width: 1.5em;
	height: 1.5em;
	top: -0.25em;
	right: 1.5em;
} */

/* Laptop */
@media (max-width: 1550px) {
	.container {
		font-size: 14px;
	}
}

/* Tablet */
@media (max-width: 900px) {
	.container {
		font-size: 16px;
		/* max-width: 665px;
		height: 340px; */
	}
	/* .smallCard {
		width: 280px;
	}
	.middleCard {
		width: 330px;
	} */
	div.middleCard:hover div:nth-of-type(1)::after,
	div.middleCard:hover div:nth-of-type(1) > button,
	div.smallCard:hover div:nth-of-type(1)::after,
	div.smallCard:hover div:nth-of-type(1) > button {
		display: none;
	}
	.middleCard:hover .img > button {
		display: block;
	}
}

/* Phone */
@media (max-width: 685px) {
	.container {
		/* height: 90vw; */
	}
	div.smallCard {
		width: calc(100vw - 36px);
	}
	/* div.smallCard:hover div:nth-of-type(1)::after,
	div.smallCard:hover div:nth-of-type(1) > button {
		display: none;
	}
	div.middleCard {
		width: 85vw;
		height: 85vw;
	} */
}
