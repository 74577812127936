// @import '../../../../css/variables/variables.scss';
form.window,
.window {
	padding: 4rem 6rem 2rem;
	width: 69rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 1.6rem;
	box-sizing: border-box;
	/* color: var(--color3); */

	& > h4 {
		margin: 0;
		font-size: 2.4rem;
		text-align: center;
	}
}

// navigate
.navigate,
.btnBack {
	display: flex;
	gap: 5px;
	align-items: center;
	align-self: flex-end;
	line-height: 18px;
	&:hover {
		cursor: pointer;
	}

	& > svg {
		width: 3.2rem;
		height: 1.6rem;

		& > path {
			fill: var(--color3);
		}
	}
}
.btnBack {
	align-self: flex-start;

	& > svg {
		transform: rotate(180deg);
	}
}

// inputBlock
.inputBlock,
.inputError {
	display: flex;
	flex-direction: column;
	gap: 10px;
	width: 100%;

	& input {
		padding: 1.6rem 3.2rem;
		text-align: center;
		font-size: 1em;
		border-radius: 4.2rem;
		&::placeholder {
			color: #cecece;
		}
	}
}

.inputError input {
	border: 1px solid var(--color4);
}

.error {
	align-self: flex-start;
	color: var(--color4);
}

// rememberBlock
.rememberBlock {
	display: flex;
	justify-content: space-between;
	width: 100%;

	& > div {
		display: flex;
		align-items: center;
		gap: 4px;
		color: var(--color3);

		&:hover {
			cursor: pointer;
		}
	}
}

// entryBlock
.entryBlock {
	display: flex;
	flex-direction: column;
	gap: 0.65rem;
	width: 100%;

	& > button {
		border-radius: 4.2rem;
		line-height: normal;

		&:disabled {
			cursor: auto;
			opacity: 0.3;
			color: #fff;
			background: var(--gradient1);
		}
	}

	& > div {
		display: flex;
		flex-direction: column;
		gap: 0.65rem;
		color: var(--color1);
		text-align: justify;
		font-size: 0.7em;
		line-height: normal;

		& > label {
			display: grid;
			grid-template-columns: 18px 1fr;
			gap: 1.6rem;

			& > p > a {
				color: var(--color3);
			}
		}
	}
}

// loginOptionsBlock
.loginOptionsBlock {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 5px;

	& > p {
		font-size: 1.1em;
	}

	& > .icons {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;
		gap: 8px;

		& > svg {
			width: 5rem;
			height: 5rem;
			&:hover {
				cursor: pointer;
			}
		}
	}
}

// authTypeBlock
.authTypeBlock {
	text-align: center;
	color: var(--color1);

	& > span {
		color: var(--color3);
		&:hover {
			cursor: pointer;
		}
	}
}

/* Phone */
@media (max-width: 685px) {
	form.window {
		padding: 3rem 3.5rem 1rem;
		gap: 0.8rem;
		max-width: 95vw;
	}
}
