/* .flexBlock {
	margin: 0 0 20px;
	display: flex;
	justify-content: space-between;
} */

/* .docName {
	margin: 0;
	font-weight: 400;
	font-size: 1.7em;
	line-height: 31px;
	color: var(--color3);
} */

.docBtns {
	display: flex;
}
.docBtns > button:first-child {
	margin: 0 10px 0 0;
}

.btn {
	width: max-content;
	gap: 10px;
	/* color: #fff; */
	/* background-color: var(--color3); */
}
.btn > svg > path {
	fill: var(--color3);
}
.btn:hover > svg > path {
	fill: #fff;
}
.editorBlock {
	display: grid;
	grid-template-rows: min-content 1fr;
}

.subtitle {
	font-size: 1.2em;
	margin: 0 0 10px;
	line-height: 20px;
}

/* .littleText {
	font-weight: 400;
	font-size: 0.8em;
	line-height: 16px;
	color: var(--color3);
} */

.modalWindow {
	min-width: 500px;
}
