.card {
	padding: 2rem 5rem 2rem 15rem;
	max-width: 67rem;
	width: 47%;
	border-radius: 2.6rem;
	border: 1px solid #d1d1d1;
	background-color: #fcfcfc;
	line-height: 2rem;
	box-sizing: border-box;
	position: relative;
	z-index: 1;
}

div.img {
	position: absolute;
	top: -1.5rem;
	left: 2rem;
	width: 11rem;
	height: 11rem;
}
.img > svg {
	width: 7.2rem;
	height: 7.2rem;
}
.img > svg > g > path {
	fill: white;
}
.img > svg > g > g > path {
	stroke: white;
}
.title {
	margin: 0 0 1.8rem;
	font-size: 2.4rem;
	line-height: 2.6rem;
}

.text {
	line-height: 2rem;
}

/* Tablet */
@media (max-width: 900px) {
	.card {
		padding: 2rem 1.6rem 2rem 12.5rem;
		width: 49%;
	}
	div.img {
		width: 9.6rem;
		height: 9.6rem;
	}
	.text {
		font-size: 8px;
	}
}

/* Phone */
@media (max-width: 685px) {
	.card {
		padding: 1.6rem 1rem 1.6rem 12.5rem;
		width: 100%;
	}
	div.img {
		width: 8.6rem;
		height: 8.6rem;
	}
	.img > svg {
		width: 5.8rem;
		height: 5.8rem;
	}
	.title {
		margin: 0 0 10px;
		font-size: 14px;
	}
	.text {
		font-size: 10px;
	}
}
