.btnMenu {
	/* padding: 0 0 0 10px; */
	display: flex;
	align-items: center;
	position: relative;
	height: 40px;
	width: 30px;
	box-sizing: border-box;
}

#menuToggle {
	display: none;
}
.menuToggleLabel {
	position: absolute;
	height: 100%;
	width: 100%;
	cursor: pointer;
	z-index: 1;
}

.burgerMenu,
.burgerMenu::before,
.burgerMenu::after {
	position: absolute;
	content: '';
	right: 0;
	height: 0.4rem;
	width: 2.5rem;
	transition: transform 1s cubic-bezier(0.23, 1, 0.32, 1);
	background-color: #0a42d3;
	border-radius: 50px;
}
.burgerMenu::before {
	width: 3.8rem;
	bottom: 1.1rem;
}
.burgerMenu::after {
	width: 3.8rem;
	top: 1.1rem;
}
#menuToggle:checked ~ .burgerMenu::before {
	bottom: 0;
	transform: rotate(135deg);
}
#menuToggle:checked ~ .burgerMenu {
	background: transparent;
}
#menuToggle:checked ~ .burgerMenu::after {
	top: 0;
	transform: rotate(-135deg);
}
