.container {
	padding: 0;
	border-radius: 3.2rem 3.2rem 5rem 5rem;
	/* box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.35); */
	box-sizing: border-box;
	/* transform: ; */
	transition: all 1s;
	position: relative;
}

.messenger {
	padding: 0px 4.8rem 0px 4rem;
	/* position: absolute; */
	width: 100%;
	height: 28rem;
	/* bottom: 0; */
	display: flex;
	flex-direction: column;
	overflow-y: auto;
	box-sizing: border-box;
	transition: all 1s;

	/* z-index: 0; */
	/* transition: z-index 0.5s; */
}
.messenger::-webkit-scrollbar,
.messenger::-webkit-scrollbar-thumb {
	display: none;
}
.messenger div {
	opacity: 1;
	/* transform: scaleY(1); */
	transition: opacity 0.5s, transform 1s;
	/* transform-origin: top; */
}

.user,
.chatbot {
	margin-bottom: 0.8rem;
	padding: 0.8rem;
	max-width: 75%;
	/* border: 1px solid var(); */
	border-radius: 0.8rem;
}

.user {
	align-self: flex-end;
	background-color: #557ece;
	color: #ffffff;
	border-radius: 2rem 2rem 0.2rem 2rem;
}

.chatbot {
	align-self: flex-start;
	background-color: #f4f4f4;
	color: #333333;
}

/* .autoScroll{

} */

.cross {
	width: 2rem;
	height: 2rem;
	position: absolute;
	top: 2rem;
	right: 2rem;
}
.cross::after,
.cross::before {
	position: absolute;
	content: '';
	width: 2.8rem;
	height: 0.2rem;
	top: 1rem;
	left: -0.4rem;
	background-color: #d3d3da;
	border-radius: 0.2rem;
	transform: rotate(45deg);
}
.cross::after {
	transform: rotate(-45deg);
}
.cross:hover {
	cursor: pointer;
}

/* Phone */
@media (max-width: 685px) {
	.container {
		margin: 0 0 2.4rem;
	}
}
