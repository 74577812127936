.container {
	display: flex;
	flex-direction: column;
	gap: 15px;
}

.docName {
	margin: 0;
	font-size: 1.7em;
	font-weight: 400;
}

.littleText {
	font-weight: 400;
	font-size: 0.8em;
	line-height: 16px;
	color: var(--color5);
}

.docContainer {
	margin: 0;
	display: grid;
	grid-template-columns: 8fr 4fr;
	gap: 40px;
}
