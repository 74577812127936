.form {
	display: flex;
	flex-direction: column;
	gap: 1rem;

	&__row1,
	&__row2 {
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 2rem;
	}
	&__row2_edit {
		padding: 0.5rem 1.2rem;
		background-color: var(--color6);
		border-radius: 7px;
	}

	&__defaultText {
		padding: 0.6rem 0;
		color: var(--color8);
	}
}

.input {
	border: none;
	&::placeholder {
		color: var(--color8);
	}
}

.btn {
	display: flex;
	justify-content: center;
	align-items: center;
	color: var(--color3);
	&:hover {
		cursor: pointer;
		& > svg > path {
			fill: var(--color3);
		}
	}
	&:active {
		& > svg > path {
			fill: var(--color8);
		}
	}

	& > svg {
		width: 1.8rem;
		height: 1.8rem;
	}
}
