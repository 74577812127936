.container {
	display: flex;
	align-items: center;
	gap: 1rem;
}

.container > svg {
	width: 4rem;
	height: 6rem;
}

.container > p {
	font-family: 'Snell-Roundhand';
	font-size: 3.2rem;
	/* font-size: 33px; */
}

.container > p > span {
	font-size: 4.2rem;
	/* font-size: 42px; */
	color: var(--color4);
}
