.statusBlock {
	display: grid;
	grid-template-columns: 2fr 3fr;
	gap: 4rem;
}
.statusBlock > div {
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 1rem;
	font-size: 1.2rem;
}
.statusBlock > div:last-of-type {
	align-items: center;
	color: var(--color3);
}

.btn {
	padding: 0.8rem 2rem;
	min-width: 100%;
	width: max-content;
	gap: 0.8rem;
	font-size: 1.2rem;
	line-height: normal;
}

/* HARDCODE */

.statusBlock > div:first-of-type > p {
	padding: 1rem 1.6rem;
	width: max-content;
	color: #fff;
	background-color: #28b811;
	border-radius: 1.2rem;
	box-sizing: border-box;
}
.statusBlock > div:first-of-type > p:last-of-type {
	background-color: #d1ae33;
}
