footer > section {
	padding: 2rem 0;
	display: flex;
	flex-direction: column;
	text-align: center;
	gap: 2rem;
}

.had {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	justify-items: center;
}
/* .had > div {
	display: flex;
	align-items: center;
	gap: 10px;
} */

.had > div:last-child {
	display: flex;
	align-items: center;
	justify-self: end;
	gap: 2rem;
}

.had > div:last-child > svg {
	width: 3.8rem;
	height: 3.8rem;
	transform: translateY(0);
	transition: all 500ms;
}
.had > div:last-child > svg:hover {
	cursor: pointer;
	transform: translateY(-2rem);
}

.links {
	display: flex;
	justify-content: space-between;
}
.links > a {
	font-size: 1.6rem;
}

/* Phone */
@media (max-width: 685px) {
	.had {
		grid-template-columns: 1fr;
		gap: 10px;
	}
	.had > div:first-child,
	.had > div:nth-child(2) > svg {
		display: none;
	}
	.had > div:last-child {
		justify-self: auto;
	}
	.links {
		display: none;
	}
}
