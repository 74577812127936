.container {
	display: flex;
	flex-direction: column;
	gap: 1rem;
}

.data {
	padding: 0.6rem 0;
	&_null {
		color: var(--color8);
	}
}
