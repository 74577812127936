.container {
	display: flex;
	align-items: center;
	gap: 4px;
}

.container > input[type='checkbox'] {
	/* margin: 0; */
	/* width: auto; */
	/* opacity: 0; */
	position: absolute;
	visibility: hidden;

	/* z-index: -1; */
}

.container > div {
	margin: 3px 3px;
	display: block;
	width: 18px;
	height: 18px;
	border: 2px solid var(--color3);
	border-radius: 3px;
	box-sizing: border-box;
}
