.container {
	display: flex;
	flex-direction: column;
	gap: 3rem;
}

.title {
	font-size: 2rem;
	font-weight: 500;
	line-height: normal;
	text-transform: uppercase;
}

.form {
	display: flex;
	flex-direction: column;
	gap: 3.2rem;
	font-size: 1.8rem;
}

.form > button {
	padding: 0.8rem 5.6rem;
	width: max-content;
	gap: 0.8rem;
	font-size: 1.2rem;
}

.input {
	padding: 1rem 2rem;
	max-width: 400px;
	font-size: 1.4rem;
	border: 1px solid #bcc2c7;
	border-radius: 1.6rem;
	box-sizing: border-box;
}
.input::placeholder {
	color: #bcc2c7;
}

@media (max-width: 900px) {
	.title {
		font-size: 20px;
	}
	.form {
		font-size: 16px;
	}
	.form > button {
		padding: 0.5em 3.5em;
		gap: 0.5em;
		font-size: 0.75em;
	}
}
