.container {
	margin: 0 0 15rem;
	display: flex;
	flex-direction: column;
	gap: 3rem;
	position: relative;

	& > img {
		position: absolute;
		top: 15rem;
		left: 50%;
		width: 62.5rem;
		transform: translate(-50%);
	}
}

.cards {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	row-gap: 4.375rem;
}

/* Tablet */
@media (max-width: 900px) {
	.container {
		display: flex;
		flex-direction: column;

		& > h3 {
			align-self: center; //
		}

		& > img {
			top: 5rem;
			width: 100%;
		}
	}
}

/* Phone */
@media (max-width: 685px) {
	.cards {
		flex-direction: column;
		align-items: center;
		row-gap: 1.5rem;
	}
	.container > img {
		display: none;
	}
}
