.container {
	display: flex;
	flex-direction: column;
	gap: 4rem;
}

.section {
	display: flex;
	flex-direction: column;
	gap: 3rem;
}

.cardList {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 3rem;
}

.stepCard {
	display: flex;
	flex-direction: column;
	gap: 2rem;

	& > img {
		max-height: 25rem;
		border-radius: 1.4rem;
		box-shadow: 2px 2px 34px 0px rgba(0, 0, 0, 0.25);
		box-sizing: border-box;
	}

	& > h4 {
		font-size: 2.4rem;

		& > span {
			color: var(--color4);
		}
	}
}

.card {
	padding: 2rem;
	gap: 1rem;

	& > h4 {
		font-size: 2.4rem;

		& > span {
			color: var(--color4);
		}
	}
}

.btn {
	margin: auto 0 0;
	padding: 1rem;
	gap: 1.5rem;
}

/* Phone */
@media (max-width: 685px) {
	.cardList {
		grid-template-columns: 1fr;
		gap: 2rem;
	}
}
