.container {
	display: grid;
	grid-template-rows: max-content max-content max-content 1fr;
	min-height: 60vh;
	max-height: 86vh;
	box-sizing: border-box;
}

.subtitle {
	margin: 0 0 10px;
	line-height: 20px;
}

.littleText {
	display: block;
	margin: 0 0 15px;
}

.containerInputWithBtn {
	margin: 0 0 5px;
}

.createFieldInput {
	margin: 0;
	border: none;
}
.createFieldInput::placeholder {
	color: var(--color3);
	opacity: 1; /* Firefox */
}

.blockType {
	margin: 0 0 20px;
	display: grid;
	grid-template-areas: 'type 2 3' 'type 4 5' 'type 6 7';
}
.blockType > span {
	grid-area: type;
}

.blockRadio:hover {
	cursor: pointer;
}

.inputRadio {
	margin: 3px 3px 0 5px;
	width: auto;
}

.listContainer {
	padding: 10px 0 0;
	/* max-height: 69vh;  */
	overflow-y: auto;
}
.listContainer::-webkit-scrollbar,
.listContainer::-webkit-scrollbar-thumb {
	width: 8px;
	background-color: #e7e7e7;
	border-radius: 8px;
}
.listContainer::-webkit-scrollbar-thumb {
	background-color: var(--color5);
}
