.section {
	margin: 0;
	padding: 30px 20px;
	display: flex;
	flex-direction: column;
	gap: 3rem;
	flex: 1;
	width: 100%;
	font-size: 16px;
	background-color: var(--color6);
	box-sizing: border-box;
}

.title {
	/* margin: 0 0 1.875rem; */
	font-size: 1.25em;
	font-weight: 500;
	line-height: normal;
	text-transform: uppercase;
}

.btnBack {
	margin: 0;
	padding: 0;
	display: flex;
	align-items: center;
	gap: 10px;
	width: max-content;
	font-size: 1.125em;
	line-height: normal;
	background-color: inherit;
}

.categoryList {
	padding: 0;
	flex-direction: column;
	align-items: flex-start;
	gap: 1.25em;
}

@media (max-width: 900px) {
	.section {
		max-width: none;
	}
}
