.container,
.appealsBlock {
	display: flex;
	flex-direction: column;
	gap: 3rem;
}

.title {
	font-size: 2rem;
	font-weight: 500;
	line-height: normal;
	text-transform: uppercase;
}

.form {
	display: flex;
	flex-direction: column;
	gap: 2rem;
	max-width: 765px;
}

.form > label {
	display: flex;
	flex-direction: column;
	gap: 0.8rem;
	font-size: 1.4rem;
	font-weight: 500;
}

.input,
textarea {
	padding: 1rem 2rem;
	font-size: 1.4rem;
	border: 1px solid #bcc2c7;
	border-radius: 1.6rem;
	box-sizing: border-box;
}
.input::placeholder,
textarea::placeholder {
	color: #bcc2c7;
}

.form > div {
	display: flex;
	justify-content: space-between;
}

.file {
	display: flex;
	align-items: center;
	gap: 1rem;
	font-size: 1.4rem;
	font-weight: 500;
}
.file > input {
	position: absolute;
	visibility: hidden;
}

.btn {
	padding: 0.8rem 4.8rem;
	/* min-width: 100%; */
	width: max-content;
	gap: 0.8rem;
	font-size: 1.2rem;
	line-height: normal;
}

.appealsHeadRow {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.appealsHeadRow > div {
	display: flex;
	align-items: center;
	gap: 2rem;
}
.appealsHeadRow > p {
	color: var(--color3);
	font-size: 1.4rem;
}

.search {
	padding: 0.6rem 1.6rem;
	display: flex;
	align-items: center;
	border-radius: 2.6rem;
	background-color: #f6f6f6;
	box-sizing: border-box;
}
.search > input {
	border: none;
}
.search > input::placeholder {
	color: var(--color5);
}
.search > svg {
	width: 2rem;
	height: 2rem;
}
.search > svg > path {
	fill: var(--color5);
}

.appeal {
	padding: 2rem 3rem;
	display: flex;
	flex-direction: column;
	gap: 1rem;
	font-size: 1.4rem;
	border: 1px solid #bcc2c7;
	border-radius: 2.6rem;
	box-sizing: border-box;
}

.appealHead {
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: 1.6rem;
	font-weight: 500;
}
.appealHead > p:last-of-type {
	font-size: 1.2rem;
	font-weight: 400;
}

.appealLastRow {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.appealLastRow > button {
	padding: 0;
	color: var(--color3);
	background-color: transparent;
}

@media (max-width: 900px) {
	.container {
		padding: 0 0 50px;
	}
	.title {
		font-size: 20px;
	}
	.form {
		font-size: 16px;
	}

	.form > label {
		gap: 0.5em;
		font-size: 16px;
	}
	.input,
	textarea {
		padding: 10px 20px;
		font-size: 14px;
		background-color: #fff;
	}
	.form > div {
		/* justify-content: space-between; */
		flex-direction: column;
		gap: 10px;
	}

	.file {
		gap: 0.625em;
		font-size: 14px;
	}
	.file > input {
		position: absolute;
		visibility: hidden;
	}

	.btn {
		padding: 0.5em 3em;
		gap: 0.5em;
		font-size: 0.75em;
	}
	.search {
		display: none;
	}
	.appealsHeadRow > div > .title {
		font-size: 16px;
	}
	.appealsHeadRow > p {
		font-size: 12px;
	}
	.appeal {
		padding: 10px 20px;
		/* flex-direction: column; */
		gap: 0.625em;
		font-size: 14px;
		/* border: 1px solid #bcc2c7; */
		border-radius: 1.625em;
		/* box-sizing: border-box; */
	}
	.appealLastRow > button {
		font-size: 14px;
	}
}
