.container {
	margin: 0 0 10rem;
	display: flex;
	flex-direction: column;
	gap: 3rem;
}

.wrp {
	display: grid;
	grid-template-columns: repeat(2, max-content);
	justify-content: space-between;
}

.form {
	/* padding: 4rem 0 4rem 4rem; */
	border-radius: 2.7rem;
	border: 1px solid var(--color5);
	gap: 0;
	box-sizing: border-box;

	& > h4 {
		color: var(--color3);
	}
}

.content {
	display: flex;
	flex-direction: column;
	gap: 1rem;

	& > p {
		margin: 0 0 0.2rem;
	}

	& > input,
	& > textarea {
		padding: 1rem 4rem;
		text-align: center;
		/* font-size: 1.6rem; */
		font-weight: 500;
		/* line-height: normal; */
		color: var(--color1);
		border-radius: 2.2rem;
		border: 1px solid var(--color5);
		box-sizing: border-box;
		resize: none;
	}

	& > input::placeholder,
	& > textarea::placeholder {
		color: var(--color5);
	}

	& > button {
		padding: 1rem 4rem;
		gap: 1rem;
	}
}

/* Tablet */
@media (max-width: 900px) {
	.container {
		display: flex;
		flex-direction: column;
	}
	// .container > h3 {
	// 	align-self: center; //
	// }
	.wrp {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 8rem;
	}
	.form {
		// padding: 30px;
		font-size: 12px;
		/* width: 380px; */
		& > h4 {
			font-size: 20px;
		}
	}
	.content > input,
	.content > textarea {
		font-size: 12px;
	}
}

/* Phone */
@media (max-width: 685px) {
	.wrp {
		gap: 5.6rem;
	}
	.form,
	.content {
		width: 100%;
		text-align: center;
	}
}
