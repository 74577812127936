.container {
	// padding: 4.5rem 0 6.8rem;
	display: flex;
	flex-direction: column;
	gap: 4rem;
	// background-color: var(--color6);
	// flex: 1;

	& > section {
		width: 100%;
	}
}

.navContainer {
	margin: 0 auto;
	width: 100%;
	max-width: 1440px;
	display: flex;
	justify-content: space-between;
	align-items: center;

	& > nav {
		margin: 0;
		max-width: none;
	}
}

.headBlock {
	display: grid;
	grid-template-columns: 40% 1fr;
	gap: 6rem;

	& > img {
		width: 100%;
		max-height: 330px;
	}

	& > div {
		display: grid;
		grid-template-columns: 55% 1fr;
		grid-template-areas: 'name purchase' 'author author';
		gap: 3.2rem;
	}
}

.nameBlock {
	grid-area: name;
	padding: 3.2rem;
	background-color: #fff;
	border-radius: 1.6rem;
	box-sizing: border-box;

	& > h5 {
		color: var(--color3);
		font-size: 2.2rem;
		font-weight: 300;
		line-height: normal;
	}

	& > h4 {
		color: var(--color1);
		font-size: 2.6rem;
	}
}

.purchaseBlock {
	grid-area: purchase;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-end;
	gap: 1.2rem;
	background-color: #fff;
	/* border: 1px solid var(--color3); */
	border-radius: 1.8rem;
	box-sizing: border-box;

	& > p:nth-of-type(1) {
		font-size: 2.2rem;
	}

	& > p:nth-of-type(2) {
		font-size: 2.8rem;
		color: var(--color3);
	}

	& > button {
		padding: 2.2rem;
		gap: 1.6rem;
		font-size: 3.6rem;
		font-weight: 700;
		color: #fff;
		background: var(--gradient1);
		border-radius: 1.8rem;
		box-sizing: border-box;
	}

	& > button > svg {
		width: 4rem;
		height: 2.8rem;
	}
}

.authorBlock {
	grid-area: author;
	padding: 2rem 3.2rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: #fff;
	border-radius: 1.8rem;
	box-sizing: border-box;

	& > div:nth-of-type(1) {
		display: flex;
		align-items: baseline;
		gap: 1rem;
		font-size: 2.2rem;
	}

	& > div:nth-of-type(1) > div > p {
		font-size: 4rem;
	}

	& > div:nth-of-type(1) > div > p > span {
		font-size: 5.6rem;
	}

	& > div:nth-of-type(2) {
		display: flex;
		flex-direction: column;
		gap: 0.8rem;
	}
}

.descriptionBlock {
	display: flex;
	flex-direction: column;
	gap: 3.2rem;
	font-size: 2rem;

	& > h3,
	.stagesBlock > h3 {
		margin: 0;
	}

	& > .info {
		padding: 3.2rem;
		background-color: #fff;
		border-radius: 1.8rem;
		box-sizing: border-box;
	}

	& > .info > p {
		margin: 0 0 2rem;
		font-size: 2rem;
		font-weight: 500;
	}

	& > .info > ol {
		display: flex;
		flex-direction: column;
		gap: 1rem;
	}
}

.stagesBlock {
	margin: 0 auto 6.8rem;
	display: flex;
	flex-direction: column;
	gap: 3.2rem;

	& > div {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		gap: 3.2rem;
	}
}

.whyUsBlock > div {
	margin: 0;
}

.item {
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	/* max-width: 25%; */

	& > div:nth-of-type(1) {
		margin: 0 0 -2.4rem;
	}

	&:nth-of-type(4) > div:nth-of-type(1) {
		background: var(--gradient2);
	}

	& > div:nth-of-type(1) > svg {
		width: 6rem;
		height: 6rem;
	}

	& > div:nth-of-type(1) > svg > g > path {
		fill: #fff;
	}

	&:nth-of-type(1) > div:nth-of-type(1) > svg > g > path,
	&:nth-of-type(4) > div:nth-of-type(1) > svg > g > path {
		stroke: #fff;
	}

	& > div:nth-of-type(2) {
		padding: 5rem 3.2rem 3.2rem;
		flex-grow: 1;
		display: flex;
		flex-direction: column;
		align-items: center;
		background-color: #fff;
		border-radius: 1.8rem;
		box-sizing: border-box;
	}

	& > div:nth-of-type(2) > h5 {
		width: max-content;
		font-size: 2.4rem;
	}

	& > div:nth-of-type(2) > p {
	}
}

/* Laptop */

@media (max-width: 1550px) {
	.navContainer {
		max-width: 1000px;
	}
}

@media (max-width: 1024px) {
	.navContainer {
		max-width: 850px;
	}
}

/* Tablet, mobile */
@media (max-width: 900px) {
	.container {
		padding: 0;
	}
	.navContainer,
	.headBlock > img {
		display: none;
	}

	.headBlock {
		grid-template-columns: 1fr;
	}
	.headBlock > div {
		grid-template-columns: 60% 1fr;
		row-gap: 0;
		/* gap: 30px; */
		/* grid-template-areas: 'name purchase' 'author 1'; */
	}

	.nameBlock {
		padding: 0;
		background-color: inherit;
	}
	.nameBlock > h5 {
		font-size: 16px;
	}
	.nameBlock > h4 {
		font-size: 20px;
	}

	.purchaseBlock {
		padding: 15px 0 0;
	}

	.authorBlock {
		margin: -30px 0 0;
		padding: 0;
		flex-direction: column-reverse;
		align-items: flex-start;
		background-color: inherit;
	}
	.authorBlock > div:nth-of-type(2) {
		flex-direction: row;
		gap: 20px;
	}
	.authorBlock > div:nth-of-type(2) > span {
		display: flex;
		flex-direction: column;
	}

	.descriptionBlock > h3,
	.stagesBlock > h3,
	.whyUsBlock > div > h3 {
		font-size: 20px;
	}
	.descriptionBlock > p,
	.descriptionBlock > .info,
	.descriptionBlock > .info > p {
		font-size: 14px;
	}

	.stagesBlock > h3 {
		align-self: center;
	}
	.stagesBlock > div {
		grid-template-columns: repeat(2, 1fr);
	}
}

/* Phone */
@media (max-width: 685px) {
	.headBlock,
	.descriptionBlock,
	.stagesBlock,
	.whyUsBlock {
		padding: 0;
	}

	.headBlock > div {
		grid-template-columns: 1fr;
		grid-template-areas: 'name' 'author' 'purchase';
		row-gap: 10px;
	}

	.authorBlock {
		margin: 0;
	}
	.authorBlock > div:nth-of-type(1) > div > p {
		font-size: 3.2rem;
	}

	.purchaseBlock {
		padding: 0 0 0 20px;
		display: grid;
		grid-template-areas: 'text btn' 'sum btn';
		gap: 0;
		justify-content: stretch;
	}
	.purchaseBlock > p:nth-of-type(1) {
		grid-area: text;
	}
	.purchaseBlock > p:nth-of-type(2) {
		grid-area: sum;
		font-size: 18px;
	}
	.purchaseBlock > button {
		grid-area: btn;
		font-size: 20px;
	}

	.stagesBlock > div {
		gap: 10px;
	}

	.item > div:nth-of-type(1) {
		width: 50px;
		height: 50px;
	}
	.item > div:nth-of-type(1) > svg {
		width: 30px;
		height: 30px;
	}

	.item > div:nth-of-type(2) {
		padding: 5rem 0 0;
		background-color: inherit;
		font-size: 10px;
	}
	.item > div:nth-of-type(2) > h5 {
		font-size: 14px;
		width: auto;
	}
}
