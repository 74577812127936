.container {
	display: flex;
	flex-direction: column;
	gap: 1.875em;
	max-width: 500px;
}

.subscription {
	display: flex;
	justify-content: space-between;
	gap: 10px;
	/* width: 100%; */
}
.card {
	padding: 1.2rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 4px;
	width: 100%;
	max-width: 170px;
	font-size: 13px;
	border-radius: 20px;
	background-color: #fff;
	box-sizing: border-box;
}

.list {
	display: flex;
	flex-direction: column;
	gap: 1.25em;
}

.itemMenu {
	padding: 0.625em 1em;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	font-size: 1em;
	background: #fff;
	border-radius: 20px;
	box-sizing: border-box;
}
