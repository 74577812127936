.container {
	padding: 3.5rem 3rem 2rem 2rem;
	display: flex;
	flex-direction: column;
	gap: 2.4rem;
	width: 70%;
	background-color: var(--color6);
	border-radius: 2rem;
	box-sizing: border-box;
}

.headRow {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.title {
	font-size: 2rem;
	font-weight: 500;
	line-height: normal;
	text-transform: uppercase;
}

.paySystems {
	display: flex;
	align-items: center;
	gap: 2rem;
}

.paySystems > svg {
	width: 4.4rem;
	height: 4.4rem;
}

.input {
	padding: 1rem 4rem;
	font-size: 1.6rem;
	border-radius: 2rem;
	border: 1px solid #dbdbdb;
}

.lastRow {
	display: flex;
	/* grid-template-columns: 45% 55%; */
	gap: 1rem;
	align-items: flex-start;
	box-sizing: border-box;
}
.lastRow > div:first-of-type {
	display: flex;
	gap: 0.8rem;
}

.cardData {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 0.4rem;
}
.cardData > input {
	padding: 1rem;
	max-width: 9.6rem;
	text-align: center;
}
.cardData > p {
	font-size: 1rem;
}

.cardData:last-of-type {
	margin: 0 0 0 10px;
	flex-direction: row;
}
.cardData:last-of-type > input,
.cardData:last-of-type > p {
	max-width: 10rem;
}

@media (max-width: 900px) {
	.container {
		font-size: 18px;
		background-color: #fff;
	}
	.title {
		font-size: 1em;
	}

	.paySystems > svg {
		width: 2em;
		height: 2em;
	}

	.input {
		font-size: 0.77em;
	}
	.cardData > p {
		font-size: 0.55em;
	}
	.cardData:last-of-type > input,
	.cardData:last-of-type > p {
		max-width: 100px;
	}
}

@media (max-width: 600px) {
	.container {
		width: 100%;
		/* font-size: 14px; */
	}
}

@media (max-width: 360px) {
	.title {
		font-size: 0.7em;
	}

	.paySystems > svg {
		width: 1.5em;
		height: 1.5em;
	}
	.input {
		padding: 1rem;
		font-size: 0.7em;
	}
	.cardData > p {
		font-size: 0.4em;
	}
}
