.block {
	margin: 0 auto;
	max-width: 1440px;
	width: 100%;
	box-sizing: border-box;
}

/* Laptop */
@media (max-width: 1550px) {
	.block {
		max-width: 1000px;
	}
}

@media (max-width: 1024px) {
	.block {
		max-width: 850px;
	}
}

/* Tablet */
@media (max-width: 900px) {
	.block {
		max-width: 665px;
	}
}

/* Phone */
@media (max-width: 685px) {
	.block {
		padding: 0 2.4rem;
	}
}
