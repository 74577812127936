.menu {
	margin: 0;
	padding: 0;
	width: 19rem;
	position: absolute;
	top: 4.2rem;
	right: 0;
	list-style-type: none;
	background-color: #fff;
	border: 1px solid #d3d3da;
	border-radius: 0.8rem;
	box-sizing: border-box;
	z-index: 1;
}

.item {
	padding: 0 1.1rem;
	line-height: normal;
	box-sizing: border-box;
	transition: all 200ms;
}
.item > a {
	font-size: 1.2rem;
}
.item:first-child {
	border-radius: 0.8rem 0.8rem 0 0;
}
.item:last-child {
	border-radius: 0 0 0.8rem 0.8rem;
}
.item:first-child .link {
	border-top: 1px solid #fff;
}
.item:last-child .link {
	display: flex;
	align-items: center;
	gap: 0.4rem;
	color: var(--color-blue);
}

.item:hover {
	cursor: pointer;
	background-color: var(--color3);
}
.item:hover .link {
	color: #fff;
	border-top: 1px solid var(--color3);
}
.item:hover .icon {
	fill: #fff;
}

.link {
	padding: 1rem 0.8rem;
	display: block;
	color: #555;
	text-decoration: none;
	border-top: 1px solid #d3d3da;
	box-sizing: border-box;
}

.icon {
	fill: var(--color3);
}
