.container {
	display: flex;
	flex-direction: column;
	gap: 4rem;
}

.section {
	display: flex;
	flex-direction: column;
	gap: 2rem;
}

.logo {
	display: flex;

	& > svg {
		height: 6rem;
		width: 4rem;
	}
}

.usefulSection {
	& > div {
		margin: 0;
	}
}

.docList {
	display: flex;
	flex-wrap: wrap;
	gap: 1rem;

	&__item {
		max-width: 25rem;
		& > img {
			width: 100%;
			border-radius: 2rem;
			border: 1px solid #cacaca;
			box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.35);
		}
		& > a {
			display: flex;
			gap: 1rem;
			font-size: 1.4rem;
			text-decoration: underline;
		}
	}
}

/* Phone */
@media (max-width: 685px) {
	.section {
		text-align: center;
	}
}
